import React, { useMemo } from 'react';
import {
  Box,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Question from './Question';
import { questionContent } from './questionContent';
import QuestionDetail from './QuestionDetail';
import Search from './Search';
import { isNil } from 'lodash';
import useTitle from 'hooks/useTitle';

const FAQ = () => {
  const classes = useStyles();
  const params = useParams<{ questionParam: string }>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isquestionSelectedPage = params.questionParam !== 'all';
  const location = useLocation();

  const history = useHistory<number | undefined>();
  const state = history.location.state;

  const onRedirectToAllQuestion = () => {
    if (location.pathname === '/faq/all') return;
    history.push('/faq/all');
  };

  const onRedirectHome = () => {
    history.push('/');
  };

  const result = useMemo(() => {
    if (params.questionParam !== 'all' && !isNil(state)) {
      return questionContent.filter((ele) => ele.id === state);
    }
    return [];
  }, [params, state]);

  useTitle('FAQ | Mintedgem');

  return (
    <div className={classes.wrapper}>
      {!(isDesktop && isquestionSelectedPage) && <Search />}
      <Box
        className={classes.container}
        marginTop={isquestionSelectedPage ? 7 : 5}
      >
        {params.questionParam === 'all' && (
          <Box className={classes.main}>
            {!isDesktop && (
              <p className={classes.crumpet}>
                <span onClick={onRedirectHome}>Home · </span>
                <span
                  onClick={onRedirectToAllQuestion}
                  style={{ fontWeight: 700 }}
                >
                  FAQ
                </span>
              </p>
            )}
            <Typography className={classes.title}>FAQ</Typography>
            <Typography className={classes.description}>
              Learn answers to frequently asked questions on Mintedgem.
            </Typography>
            <Box>
              <Divider />
              {questionContent.map((question) => (
                <Question
                  questionValue={question}
                  isDetail={false}
                  key={question.id}
                />
              ))}
            </Box>
          </Box>
        )}
        {params.questionParam !== 'all' && (
          <>
            {!isDesktop && (
              <p className={classes.crumpet}>
                <span onClick={onRedirectHome}>Home · </span>
                <span
                  onClick={onRedirectToAllQuestion}
                  style={{ fontWeight: 700 }}
                >
                  FAQ
                </span>
              </p>
            )}
            <QuestionDetail
              questionSelected={
                result.length > 0 ? result[0] : questionContent[0]
              }
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default FAQ;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '160px 0px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '136px 0px',
    },
  },
  container: {
    color: '#100113',
    width: '100%',
    marginTop: 'unset !important',
    padding: '0 27px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px 100px',
    },
  },
  main: {
    paddingTop: 40,
    marginLeft: 290,
    marginRight: 290,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  crumpet: {
    color: '#6F6BC5',
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 22,
  },
  direct: {
    paddingTop: 40,
    paddingLeft: 100,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '12px',
    color: '#6F6BC5',
  },
  title: {
    fontSize: 42,
    fontWeight: 700,
    lineHeight: '58.8px',
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22.4px',
    marginBottom: 80,
  },
  question: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '22.4px',
    padding: '20px 24px 20px 24px',
    width: '100%',
  },
  boxQuestion: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 25,
  },
  footer: {
    backgroundColor: '#6F6BC5',
    position: 'absolute',
    top: '900px',
    bottom: 0,
    width: '100%',
  },
}));
