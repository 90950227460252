import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '64px',
    [theme.breakpoints.between('md', 1439)]: {
      marginTop: '50px',
    },
    [theme.breakpoints.between(0, 'md')]: {
      marginTop: '80px',
    },
  },
  wrapButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '24px',
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
      marginLeft: '8px',
      color: '#6F6BC5',
    },
    [theme.breakpoints.between('md', 1439)]: {
      marginBottom: '30px',
    },
    [theme.breakpoints.between(375, 426)]: {
      marginBottom: '16px',
    },
  },
  wrapItemScroll: {
    width: '100%',
    height: '252px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '17px 0px 0px 17px',
    position: 'relative',
    cursor: 'pointer',
    [theme.breakpoints.between('md', 1439)]: {
      width: '100%',
    },
  },
  wrapInfor: {
    position: 'absolute',
    left: '8px',
    bottom: '16px',
  },
  wrapPrice: {
    width: 'auto',
    height: '27px',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    padding: '4px',
    marginRight: '9px',
    lineHeight: '20px',
  },
  wrapPriceBlack: {
    width: 'auto',
    height: '27px',
    backgroundColor: 'black',
    borderRadius: '16px',
    padding: '4px',
    marginRight: '9px',
    lineHeight: '20px',
  },
  ethIcon: {
    width: '7px',
    height: '11px',
    marginLeft: '4px',
  },
  iconItem: {
    width: '11px',
    height: '11px',
    marginLeft: '4px',
  },
  totalItem: {
    width: 'auto',
    height: '17px',
    fontWeight: 600,
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '6px',
    marginRight: '6px',
  },
  priceItem: {
    // width: '42px',
    height: '17px',
    fontWeight: 600,
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '6px',
    marginRight: '6px',
  },
  priceItemBlack: {
    // width: '42px',
    height: '17px',
    fontWeight: 600,
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '6px',
    marginRight: '6px',
    color: 'white',
  },
  overFlow: {
    width: '42px',
  },
  totalItemBlack: {
    width: 'auto',
    height: '17px',
    fontWeight: 600,
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '6px',
    marginRight: '6px',
    color: 'white',
  },
  titlePrice: {
    fontSize: '12px',
    height: '17px',
    fontWeight: 400,
    color: '#100113',
    marginRight: '4px',
  },
  titlePriceBlack: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'white',
    marginRight: '4px',
  },
  wrapInforNFT: {
    width: 'auto',
    height: '25px',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    padding: '4px',
    marginLeft: '8px',
  },
  imgNFTItem: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
  },
  nameNFT: {
    height: '17px',
    fontWeight: 600,
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '4px',
    marginRight: '8px',
  },
  titleItemNFT: {
    fontSize: '45px',
    fontWeight: 700,
    color: '#FFFFFF',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '408px',
  },
  titleItemNFTBlack: {
    fontSize: '45px',
    fontWeight: 700,
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '408px',
  },
  desNFT: {
    fontSize: '15px',
    fontWeight: 400,
    color: '#FFFFFF',
    marginTop: '4px',
  },
  desNFTBlack: {
    fontSize: '15px',
    fontWeight: 400,
    color: 'black',
    marginTop: '4px',
  },
  linkSeeAll: {
    paddingLeft: 0,
    fontSize: '14px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#6F6BC5',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapButtonScroll: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: '24px',
  },
  iconPrev: {
    marginLeft: '24px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      marginBottom: '20px',
      '& img': {
        width: '44px',
      },
    },
  },
  imgArrowAll: {
    marginLeft: '8px',
  },
}));
