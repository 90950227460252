import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

function Privacy() {
  const classes = useStyles();
  const history = useHistory();

  const onRedirectHome = () => {
    history.push('/');
  };

  const onRedirectPrivacy = () => {
    history.push('/privacy');
  };
  return (
    <div className={classes.container}>
      <div className={classes.crumpet}>
        <span onClick={onRedirectHome}>Home · </span>
        <span onClick={onRedirectPrivacy} className={classes.crumpetPricacy}>
          Privacy
        </span>
      </div>
      <div className={classes.header}>Privacy Policy</div>
    </div>
  );
}

export default Privacy;

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 211.25px)',
    paddingLeft: 24,
    paddingRight: 24,
  },
  crumpet: {
    color: '#6F6BC5',
    cursor: 'pointer',
    fontWeight: 500,
    position: 'relative',
    marginLeft: 79,
    [theme.breakpoints.down('md')]: {
      top: '136px',
      marginLeft: 0,
    },
    top: '180px',
  },
  crumpetPricacy: {
    fontWeight: 700,
  },
  header: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    fontWeight: 'bold',
  },
}));
