import React from 'react';
import { SortEnum } from 'enums/sortEnum';
import DiscoverTop from './DiscoverTop';
import ScrollCategories from './ScrollCategories';
import TableCategories from './TableCategories';
import { DataCategories } from 'types/categories';
import { CategoriesLabelEnum, ParamsSortBy } from 'enums/categories';
import CTAComponent, {
  ICTAComponentProps,
} from 'components/common/CTAComponent';
import { ReactComponent as ExploreIcon } from 'assets/rocket-solid.svg';
import { useHistory } from 'react-router-dom';
export interface PageHomeProps {
  allCategories: DataCategories[];
  categories: DataCategories[];
  pageSize: number;
  total: number;
  pageNumber: number;
  onChangePage: (isLoadMore?: boolean) => void;
  loading?: boolean;
  handleChangeDuration: (value: ParamsSortBy) => void;
  sortType: SortEnum;
  handleSort: (value: SortEnum, column?: CategoriesLabelEnum) => void;
  sortField: string;
}

export default function PageHome(props: PageHomeProps) {
  const {
    handleSort,
    allCategories,
    categories,
    pageSize,
    total,
    pageNumber,
    onChangePage,
    loading,
    handleChangeDuration,
    sortType,
    sortField,
  } = props;
  const history = useHistory();
  const ctaField: ICTAComponentProps = {
    headTitle: 'Add NFTs. Get 99% $DONE 🤑',
    subHeadTitle:
      '$DONE is the native token of 1001 Squares of NFT. Pay to add NFTs with MATIC, BNB Smart Chain, AVAX, FTM, ETH or DONE and receive 99% of your total ad spend instantly back in $DONE. 🤝',
    firstTitleButton: 'ABOUT',
    secondTitleButton: 'EXPLORE',
    iconButton: <ExploreIcon color="#FFFFFF" width={24} height={24} />,
    iconPosition: 'end',
    handleClickFirstBtn: () => history.push('/about'),
    handleClickSecondBtn: () => history.push('/nft'),
  };

  return (
    <div>
      <DiscoverTop categories={allCategories} />
      <ScrollCategories categories={allCategories} />
      <TableCategories
        loading={loading && loading}
        data={categories}
        count={total}
        page={pageNumber}
        onChangePage={onChangePage}
        rowsPerPage={pageSize}
        sort={sortType}
        handleChangeDuration={handleChangeDuration}
        handleSort={handleSort}
        sortField={sortField}
      />
      <CTAComponent {...ctaField} />
    </div>
  );
}
