/* eslint-disable react/jsx-no-target-blank */
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { DISCORD_LINK } from 'common/constant';
import { renderShortAddress } from 'common/helper';
import ConnectWalletDialog from 'components/ConnectWallet';
import makeBlockie from 'ethereum-blockies-base64';
import AccountBalanceWallet from 'icons/AccountBalanceWallet';
import CloseIcon from 'icons/CloseIcon';
import DiscordIcon from 'icons/DiscordIcon';
import InstagramIcon from 'icons/InstagramIcon';
import LogoutIcon from 'icons/LogoutIcon';
import TwitterIcon from 'icons/TwitterIcon';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import { logoutUserAction } from 'store/actions/userActions';
import { getUserState } from 'store/selectors';

interface IMenuModal {
  onClose: () => void;
}
const MenuModal = ({ onClose }: IMenuModal) => {
  const classes = useStyles();
  const user = useSelector(getUserState);
  const dispatch = useDispatch();
  const { deactivate, active } = useWeb3React();
  const history = useHistory();
  const location = useLocation();
  const [goHome, setgoHome] = useState<boolean>(false);

  const onDirectToStaticPage = (path: string) => {
    onClose();
    if (location.pathname === path) return;
    history.push(path);
  };

  const onDirectToHome = () => {
    history.push('/');
  };

  const onLogout = () => {
    deactivate();
    dispatch(logoutUserAction());
    // if (history.location.pathname == '/user') {
    //   history.push('/');
    // }
    // onClose();
    setgoHome(true);
  };

  const onLogin = useCallback(() => {
    dispatch(
      updateDialogStateAction({
        open: true,
        component: ConnectWalletDialog,
        props: undefined,
      }),
    );
  }, [dispatch]);

  return (
    <Box className={classes.main}>
      <Box className={classes.exist}>
        <Button
          className={classes.btnClear}
          onClick={() => {
            onClose();
            if (goHome) onDirectToHome();
          }}
        >
          <CloseIcon color="#FFFFFF" />
        </Button>
      </Box>
      {user.user && active ? (
        <Box className={classes.user}>
          <Box
            className={classes.avatar}
            onClick={() => onDirectToStaticPage('/user')}
          >
            <img
              alt="Avatar"
              src={makeBlockie(user.user?.address || '')}
              width="60"
              height="60"
            />
          </Box>
          <Box className={classes.userInfo}>
            <Box style={{ display: 'flex' }}>
              <Box
                className={classes.userId}
                onClick={() => onDirectToStaticPage('/user')}
              >
                {renderShortAddress(user.user.address, 6, 4)}
              </Box>
              <Box className={classes.logout} onClick={onLogout}>
                <LogoutIcon color="#FFFFFF" width={20} height={18} />
              </Box>
            </Box>
            <Box className={classes.wallet}>Wallet</Box>
          </Box>
        </Box>
      ) : (
        <Button className={classes.connectBtn} onClick={onLogin}>
          <AccountBalanceWallet color="#FFFFFF" />
          <Typography>Connect wallet</Typography>
        </Button>
      )}
      {/* <Box className={classes.navContainer}>
        <Box
          className={classes.itemNav}
          onClick={() => onDirectToStaticPage('/about')}
        >
          About
        </Box>
        <Box
          className={classes.itemNav}
          onClick={() => onDirectToStaticPage('/faq/all')}
        >
          FAQ
        </Box>
        <Box
          className={classes.itemNav}
          onClick={() => onDirectToStaticPage('/contact')}
        >
          Contact
        </Box>
      </Box> */}
      <Box className={classes.social}>
        <Box className={classes.text}>Follow us</Box>
        <Box className={classes.icon}>
          <a href={DISCORD_LINK} target="_blank">
            <DiscordIcon color="#FFFFFF" width={24} height={24} />
          </a>
          <a href="https://twitter.com/mintedgem" target="_blank">
            <TwitterIcon color="#FFFFFF" width={24} height={24} />
          </a>
          <a href="https://www.instagram.com/mintedgem/" target="_blank">
            <InstagramIcon color="#FFFFFF" width={24} height={24} />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuModal;
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#100113',
    height: '100%',
    color: '#FFFFFF',
    textAlign: 'center',
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    height: 60,
    marginTop: 56,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 60,
    },
  },
  avatar: {
    marginRight: 40,
    marginLeft: 69,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 32,
    },
    '& img': {
      objectFit: 'cover',
      borderRadius: '50%',
      border: '4px solid rgba(255, 255, 255, 0.8)',
    },
  },
  userInfo: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
    justifyContent: 'flex-start',
  },
  userId: {
    fontSize: 20,
    lineHeight: '140%',
    fontWeight: 700,
  },
  wallet: {
    fonWeight: 400,
    fontSize: 8,
    linHeight: '140%',
    textAlign: 'left',
  },
  logout: {
    display: 'flex',
    paddingLeft: 30,
    alignItems: 'center',
  },
  exist: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    marginRight: 20,
  },
  navContainer: {
    position: 'absolute',
    top: 274,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  itemNav: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 32,
    lineHeight: '140%',
    fontWeight: 600,
    cursor: 'pointer',
  },
  social: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    bottom: 20,
    [theme.breakpoints.up('sm')]: {
      bottom: 60,
    },
  },
  icon: {
    display: 'flex',
    paddingTop: 18,
    justifyContent: 'space-around',
    paddingRight: 100,
    paddingLeft: 100,
  },
  text: {
    fontSize: 12,
  },
  connectBtn: {
    width: 'calc(100% - 40px)',
    border: '1px solid #FFFFFF',
    height: 60,
    marginTop: 56,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 60,
    },
    '& p': {
      color: '#FFFFFF',
      fontSize: 16,
      fontWeight: 600,
      marginLeft: 16,
    },
  },
  btnClear: {
    width: 60,
    height: 60,
    border: '1px solid #FFFFFF',
  },
}));
