/* eslint-disable */
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { ContactActionTypeEnum } from 'enums/actions';
import { isUndefined } from 'lodash';
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmail } from 'store/actions/contactActions';
import { sTaskStatus } from 'store/selectors';
import isEmail from 'validator/lib/isEmail';
import ContactTextField from './ContactTextField';
import { Link, useHistory } from 'react-router-dom';
import useTitle from 'hooks/useTitle';

const Contact = () => {
  const [name, setName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [subject, setSubject] = useState<string | undefined>();
  const [message, setMessage] = useState<string | undefined>();

  const history = useHistory();
  const onredirectContact = () => {
    history.push('/contact');
  };

  const onredirectHome = () => {
    history.push('/');
  };

  const dispatch = useDispatch();
  const contactStatus = useSelector(
    sTaskStatus(ContactActionTypeEnum.SEND_EMAIL),
  );
  const isSuccess = useMemo(() => {
    if (contactStatus?.error) return false;
    return true;
  }, [contactStatus]);

  const classes = useStyles({ isSuccess });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'name') {
      setName(event.target.value);
    } else if (event.target.name == 'email') {
      setEmail(event.target.value);
    } else if (event.target.name == 'subject') {
      setSubject(event.target.value);
    } else if (event.target.name == 'message') {
      setMessage(event.target.value);
    }
  };

  const emailMsgError = useMemo(() => {
    if (isUndefined(email)) return '';
    if (!isEmail(email)) return 'This value must be a valid email address';
    if (email == '') return 'This value must not be empty';
    return '';
  }, [email]);

  const disabled = useMemo(() => {
    return !isEmail(email || '') || !name || !subject || !message;
  }, [email, name, subject, message]);

  const onSendMessage = useCallback(() => {
    if (!name || !email || !subject || !message) return;
    dispatch(
      sendEmail({
        name,
        email,
        subject,
        content: message,
      }),
    );
    setName(undefined);
    setEmail(undefined);
    setSubject(undefined);
    setMessage(undefined);
  }, [dispatch, name, email, subject, message]);

  useTitle('Contact | Mintedgem');

  return (
    <Box className={classes.main}>
      <div className={classes.crumpet}>
        <span onClick={onredirectHome}>Home · </span>
        <span onClick={onredirectContact} style={{ fontWeight: 700 }}>
          Contact
        </span>
      </div>
      <Typography className={classes.title}>Contact</Typography>
      <Typography className={classes.subtitle}>
        Have a burning question you want to ask? Or maybe something went wrong?
        Let us know how we can help.
      </Typography>
      <Box bgcolor="rgba(0, 0, 0, 0.04)" mt={3}>
        <Typography className={classes.warning}>
          <span>Warning:</span>
          Mintedgem support will never contact you directly. If someone claiming
          to be us is contacting you from an email not ending in
          “@mintedgem.com” it is a scam. Let us know asap.
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <ContactTextField
          error={name == ''}
          placeholder="Your name"
          label="Name"
          required
          value={name || ''}
          onChange={handleChange}
          name="name"
          className={classes.name}
        />
        <ContactTextField
          error={!!emailMsgError}
          errorMsg={emailMsgError}
          placeholder="Your email@domain.com"
          label="Email"
          required
          value={email || ''}
          onChange={handleChange}
          name="email"
          className={classes.name}
        />
      </Box>
      <ContactTextField
        error={subject == ''}
        placeholder="What is the reason for contacting?"
        label="Subject"
        required
        value={subject || ''}
        onChange={handleChange}
        name="subject"
        className={classes.subject}
      />
      <ContactTextField
        error={message == ''}
        multiline
        rows={5}
        placeholder="Describe how we can help."
        label="Message"
        required
        value={message || ''}
        onChange={handleChange}
        name="message"
        className={classes.message}
      />
      <Box className={classes.termAndService}>
        <Typography>By SENDING I agree to Mintedgem</Typography>
        <Link to={'/terms-of-service'}>
          <Typography className={classes.termAndServiceLink}>
            {'Terms of Service.'}
          </Typography>
        </Link>
      </Box>
      <Box className={classes.send} onClick={onSendMessage}>
        <Button disabled={disabled}>SEND MESSAGE</Button>
      </Box>
      {contactStatus && !contactStatus.processing && (
        <Box className={classes.msg}>
          {isSuccess ? 'Message successfully sent' : 'Some errors occurred'}
        </Box>
      )}
    </Box>
  );
};

export default Contact;

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '200px 293px 153px 293px',
    [theme.breakpoints.down('md')]: {
      padding: '136px 24px 140px 24px',
    },
  },
  crumpet: {
    color: '#6F6BC5',
    position: 'relative',
    cursor: 'pointer',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      left: -190,
      bottom: 20,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  title: {
    fontSize: 42,
    fontWeight: 700,
    lineHeight: '58.8px',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 400,
  },
  warning: {
    fontSize: 11,
    '& span': {
      fontWeight: 700,
      '&::after': {
        content: "' '",
        whiteSpace: 'pre',
      },
    },
  },
  name: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    marginTop: 24,
    '&>div:first-child': {
      marginRight: 16,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '&>div:first-child': {
        marginBottom: 16,
      },
    },
  },
  subject: {
    width: '100%',
    marginTop: 24,
  },
  message: {
    width: '100%',
    marginTop: 24,
    height: 'fit-content',
  },
  termAndService: {
    marginTop: 24,
    display: 'flex',
    '&>p:first-child': {
      '&::after': {
        content: "' '",
        whiteSpace: 'pre',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: 12,
      },
    },
  },
  termAndServiceLink: {
    textDecoration: 'underline',
    fontWeight: 600,
    color: '#6F6BC5',
  },
  send: {
    width: '100%',
    height: 60,
    marginTop: 24,
    '& button': {
      height: 'inherit',
      float: 'right',
      backgroundColor: '#6F6BC5',
      width: 245,
      color: '#FFFFFF',
      '&.Mui-disabled': {
        color: '#FFFFFF',
        backgroundColor: 'rgba(0, 0, 0, 0.32)',
      },
    },
  },
  msg: {
    width: '100%',
    marginTop: 16,
    backgroundColor: ({ isSuccess }: { isSuccess: boolean }) =>
      isSuccess ? '#EAFDF5' : '#FFF0F0',
    fontSize: 11,
    padding: '4px 8px',
    color: ({ isSuccess }: { isSuccess: boolean }) =>
      isSuccess ? '#17C17C' : '#ED5050',
  },
}));
