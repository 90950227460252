import React, { memo } from 'react';

function RocketIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.5" y="4.5" width="29" height="29" rx="14.5" fill="#100113" />
      <g clip-path="url(#clip0_625_24858)">
        <path
          d="M15.8918 23.0274L14.9262 22.0617C14.6606 21.7961 14.5668 21.4117 14.6856 21.0555C14.7793 20.7774 14.9043 20.4149 15.0543 19.9992H11.7481C11.4793 19.9992 11.2293 19.8555 11.095 19.6211C10.9606 19.3867 10.9637 19.0992 11.1012 18.868L12.7418 16.1024C13.1481 15.418 13.8825 14.9992 14.6762 14.9992H17.2481C17.3231 14.8742 17.3981 14.7586 17.4731 14.6461C20.0325 10.8711 23.845 10.7461 26.12 11.1649C26.4825 11.2305 26.7637 11.5149 26.8325 11.8774C27.2512 14.1555 27.1231 17.9649 23.3512 20.5242C23.2418 20.5992 23.1231 20.6742 22.9981 20.7492V23.3211C22.9981 24.1149 22.5793 24.8524 21.895 25.2555L19.1293 26.8961C18.8981 27.0336 18.6106 27.0367 18.3762 26.9024C18.1418 26.768 17.9981 26.5211 17.9981 26.2492V22.8992C17.5575 23.0524 17.1731 23.1774 16.8825 23.2711C16.5325 23.3836 16.1512 23.2867 15.8887 23.0274H15.8918ZM22.9981 16.2492C23.6887 16.2492 24.2481 15.6899 24.2481 14.9992C24.2481 14.3086 23.6887 13.7492 22.9981 13.7492C22.3075 13.7492 21.7481 14.3086 21.7481 14.9992C21.7481 15.6899 22.3075 16.2492 22.9981 16.2492Z"
          fill="white"
        />
      </g>
      <rect
        x="4.5"
        y="4.5"
        width="29"
        height="29"
        rx="14.5"
        stroke="white"
        stroke-width="3"
      />
      <rect
        x="1.5"
        y="1.5"
        width="35"
        height="35"
        rx="17.5"
        stroke="white"
        stroke-opacity="0.24"
        stroke-width="3"
      />
      <defs>
        <clipPath id="clip0_625_24858">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(11 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(RocketIcon);
