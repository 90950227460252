import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  sortOptionContainer: {
    alignItems: 'center',
    height: '60px !important',
    position: 'fixed',
    minHeight: 'unset !important',
    overflowY: 'unset !important' as any,
    backgroundColor: '#FFFFFF',
    zIndex: 2,
  },
  sortLabelContainer: {
    paddingRight: 8,
  },
  sortLabel: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  sortOption: {
    width: 200,
  },
  container: {
    transition: '0.2s',
    overflowY: 'auto !important' as any,
    overflowX: 'hidden !important' as any,
    '&::-webkit-scrollbar-track': {
      borderColor: '#FFF',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 0,
      display: 'block !important',
    },
  },
}));
