import React from 'react';
import { IQuestionContent } from './questionContent';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface ISearchResult {
  questionsMatched: IQuestionContent[];
  className?: string;
  onClick: () => void;
}

function SearchResult(props: ISearchResult) {
  const { questionsMatched, className, onClick } = props;
  const classes = useStyle();
  const history = useHistory();

  const handleClick = (questionValue: IQuestionContent) => {
    history.push({
      pathname: `/faq/${questionValue.question}`
        .replace(/ /g, '-')
        .replace('?', '-'),
      state: questionValue.id,
    });
    onClick();
  };

  return (
    <div className={className}>
      {questionsMatched.length === 0 ? (
        <div>No results found</div>
      ) : (
        questionsMatched.map((q) => (
          <div
            key={q.id}
            className={classes.item}
            onClick={() => {
              handleClick(q);
            }}
          >
            {q.question}
          </div>
        ))
      )}
    </div>
  );
}

export default SearchResult;

const useStyle = makeStyles(() => ({
  item: {
    padding: '8px 0',
    borderBottom: '1px solid #000000',
    marginBottom: 6,
    '&:hover': {
      color: '#6F6BC5',
      cursor: 'pointer',
    },
  },
}));
