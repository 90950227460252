import { makeStyles, Box, Typography } from '@material-ui/core';
import CommonInput from 'components/common/CommonInput';
import React from 'react';

interface IInput {
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder?: string;
  name?: string;
  label?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  errorMsg?: string;
  type?: string;
}
const ContactTextField = ({
  value,
  onChange,
  className,
  name,
  label,
  required,
  multiline,
  rows,
  error,
  errorMsg,
  placeholder,
  type,
}: IInput) => {
  const classes = useStyles({ multiline });

  return (
    <Box className={className}>
      <Typography className={classes.label}>
        {`${required ? '* ' : ''} ${label}`}
      </Typography>
      <CommonInput
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        type={type}
        className={classes.input}
      />
      {error && (
        <Typography className={classes.error}>
          {errorMsg || 'This value must not be empty'}
        </Typography>
      )}
    </Box>
  );
};

export default ContactTextField;

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 11,
    marginBottom: 8,
  },
  error: {
    marginTop: 4,
    fontSize: 11,
    color: '#ED5050',
  },
  input: {
    height: ({ multiline }: { multiline?: boolean }) =>
      multiline ? 'auto' : 60,
    '& input': {
      height: 60,
      padding: '0px 14px',
      fontSize: 14,
      fontWeight: 400,
      '&::placeholder': {
        color: '#100113',
      },
    },
    '& textarea': {
      fontSize: 14,
      fontWeight: 400,
      '&::placeholder': {
        color: '#100113',
      },
    },
  },
}));
