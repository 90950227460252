/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyle } from './style';
import LogoWhiteIcon from 'icons/LogoWhiteIcon';
import DiscordIcon from 'icons/DiscordIcon';
import TwitterIcon from 'icons/TwitterIcon';
import InstagramIcon from 'icons/InstagramIcon';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
// import { clientRoutesEnum } from 'enums/routes';
import { DISCORD_LINK } from 'common/constant';

interface IProps {
  className?: string;
}

function AddNFTFooter(props: IProps) {
  const classes = useStyle();

  const blank = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className={clsx(props.className, classes.container)}>
      <Box display={'flex'} className={classes.topContainer}>
        <Box className={classes.leftItem}>
          <LogoWhiteIcon width={174} height={32} />
          <Typography className={classes.textFooter}>
            The #1 NFT Space. Display NFTs in 42 galleries from $0.00 a day.
          </Typography>
        </Box>
        {/* <Box className={classes.midleItem}>
          <Link to="/about">
            <Typography>ABOUT</Typography>
          </Link>
          <Link to="/faq/all">
            <Typography>FAQ</Typography>
          </Link>
          <Link to={clientRoutesEnum.CONTACT}>
            <Typography>CONTACT</Typography>
          </Link>
        </Box> */}
        <Box color={'white'} className={classes.rightItem}>
          {/* <Typography>Follow us</Typography> */}
          <div className={classes.socialIcon}>
            <div
              className={clsx(classes.iconLinkWrapper)}
              onClick={() => blank(DISCORD_LINK)}
            >
              <a href={DISCORD_LINK} target="_blank">
                <DiscordIcon color="white" width={16} height={16} />
              </a>
            </div>

            <div
              className={clsx(classes.iconLinkWrapper, classes.iconWrapSecond)}
              onClick={() => blank('https://twitter.com/mintedgem')}
            >
              <a href="https://twitter.com/mintedgem" target="_blank">
                <TwitterIcon color="white" fontSize={16} />
              </a>
            </div>
            <div
              className={clsx(classes.iconLinkWrapper)}
              onClick={() => blank('https://www.instagram.com/mintedgem/')}
            >
              <a href="https://www.instagram.com/mintedgem/" target="_blank">
                <InstagramIcon color="white" width={16} height={16} />
              </a>
            </div>
          </div>
        </Box>
      </Box>
      <Box className={classes.copyRight}>
        <Typography>
          Copyright 2024 1001 Squares of NFT
          <span className={classes.dot}>&#183;</span>
          <Link to={'/terms-of-service'} className={classes.linkRouter}>
            <span className={classes.link}>Terms</span>
          </Link>
          <span className={classes.dot}>&#183;</span>
          <Link to={'/faq/all'} className={classes.linkRouter}>
            <span className={classes.link}>FAQ</span>
          </Link>
        </Typography>
      </Box>
    </div>
  );
}

export default AddNFTFooter;
