/* eslint-disable */
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ContactTextField from 'containers/Contact/ContactTextField';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminLoginAction } from 'store/actions/adminAction';

const AdminLogin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'username') {
      setUsername(event.target.value);
    } else if (event.target.name == 'password') {
      setPassword(event.target.value);
    }
  };

  const callback = useCallback(
    (status: boolean) => {
      if (status) {
        history.push('/admin');
      } else {
        setMessage('Your username or password is incorrect');
      }
    },
    [history],
  );

  const onLogin = useCallback(() => {
    dispatch(
      adminLoginAction(
        {
          username,
          password,
          isAdmin: true,
        },
        callback,
      ),
    );
  }, [dispatch, username, password, callback]);

  return (
    <Box className={clsx(classes.container, 'center-root')}>
      <Box className={clsx(classes.main, 'center-root')}>
        <Typography className={classes.text1}>Login to your account</Typography>
        <Typography className={classes.text2}>
          Register with your information and start sharing.
        </Typography>
        <ContactTextField
          value={username}
          onChange={handleChange}
          name="username"
          placeholder="Username"
          label="Username"
          className={classes.field}
        />
        <ContactTextField
          value={password}
          type="password"
          onChange={handleChange}
          name="password"
          placeholder="Password"
          label="Password"
          className={classes.field}
        />
        <Box className={classes.wrapper}>
          {message && <Box className={classes.msg}>{message}</Box>}
          <Button className={classes.login} onClick={onLogin}>
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminLogin;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100vh',
  },
  main: {
    width: 332,
    flexDirection: 'column',
  },
  field: {
    width: '100%',
    marginTop: 24,
  },
  text1: {
    fontSize: 20,
    fontWeight: 700,
  },
  text2: {
    textAlign: 'center',
    marginBottom: 52,
  },
  login: {
    height: 60,
    width: '100%',
    backgroundColor: '#6F6BC5',
    color: '#FFFFFF',
  },
  msg: {
    width: '100%',
    top: 8,
    backgroundColor: '#FFF0F0',
    color: '#ED5050',
    position: 'absolute',
  },
  wrapper: {
    marginTop: 50,
    width: '100%',
    position: 'relative',
  },
}));
