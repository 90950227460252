import React, { useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DataTable from 'components/common/DataTable';
import {
  DataTableColumn,
  DataTableOptions,
} from 'components/common/DataTable/types';
import MenuComponent, { OptionItem } from 'components/common/MenuComponent';
import { useStyles } from './styles';
import clsx from 'clsx';
import { SortEnum } from 'enums/sortEnum';
import IconDown from 'assets/icon-table-down.svg';
import IconUp from 'assets/icon-table-up.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  CategoriesFieldEnum,
  CategoriesLabelEnum,
  ParamsSortBy,
  TopCategorySortOptions,
  VolFieldEnum,
} from 'enums/categories';
import { DataCategories } from 'types/categories';
import EthIconNew from 'icons/EthIconNew';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetBlockCategoriesAction } from 'store/actions/blockCategoriesAction';
import { updateFilterAction } from 'store/actions/filterActions';

const categoriesSortOptions: OptionItem[] = [
  {
    key: TopCategorySortOptions.ALL_TIME,
    label: 'ALL TIME VOLUME',
  },
  {
    key: TopCategorySortOptions.LAST_24_HOURS,
    label: 'LAST 24 HOURS',
  },
  {
    key: TopCategorySortOptions.LAST_7_DAYS,
    label: 'LAST 7 DAYS',
  },
  {
    key: TopCategorySortOptions.LAST_30_DAYS,
    label: 'LAST 30 DAYS',
  },
];
export interface TableCategorisProps {
  loading?: boolean;
  data: DataCategories[];
  count: number;
  page: number;
  rowsPerPage: number;
  sortField?: string;
  sort?: SortEnum;
  onChangePage: (isLoadMore?: boolean) => void;
  handleChangeDuration: (value: ParamsSortBy) => void;
  handleSort: (value: SortEnum, column?: CategoriesLabelEnum) => void;
}

export default function TableCategories(props: TableCategorisProps) {
  const {
    loading,
    count,
    page,
    rowsPerPage,
    sortField,
    sort,
    onChangePage,
    data,
    handleChangeDuration,
    handleSort,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sortdOption, setSortOption] = useState<OptionItem>(
    categoriesSortOptions[0],
  );
  const [fieldVolumn, setFieldVolumn] = useState<VolFieldEnum>(
    VolFieldEnum.ALL_TIME,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const renderIconChange = useMemo(
    () => (value: string) => {
      if (value === 'inc') {
        return <img src={IconUp} alt="" className={classes.iconUpDown} />;
      } else if (value === 'desc') {
        return <img src={IconDown} alt="" className={classes.iconUpDown} />;
      }
      return null;
    },
    [classes],
  );

  const renderVolColumn = useMemo(
    () => (value: string) => {
      if (value === TopCategorySortOptions.ALL_TIME) {
        setFieldVolumn(VolFieldEnum.ALL_TIME);
        return ParamsSortBy.ALL_TIME;
      } else if (value === TopCategorySortOptions.LAST_24_HOURS) {
        setFieldVolumn(VolFieldEnum.LAST_24_HOURS);
        return ParamsSortBy.LAST_24_HOURS;
      } else if (value === TopCategorySortOptions.LAST_30_DAYS) {
        setFieldVolumn(VolFieldEnum.LAST_30_DAYS);
        return ParamsSortBy.LAST_30_DAYS;
      }
      setFieldVolumn(VolFieldEnum.LAST_7_DAYS);
      return ParamsSortBy.LAST_7_DAYS;
    },
    [],
  );

  const handleChangeSortOption = useCallback(
    (option: OptionItem) => {
      setSortOption(option);
      handleChangeDuration(renderVolColumn(option.key));
    },
    [handleChangeDuration, renderVolColumn],
  );

  const renderTitleTable = useMemo(() => {
    if (sortdOption.key === TopCategorySortOptions.ALL_TIME) {
      return 'All time volume';
    }

    if (sortdOption.key === TopCategorySortOptions.LAST_30_DAYS) {
      return '30D volume';
    }

    if (sortdOption.key === TopCategorySortOptions.LAST_7_DAYS) {
      return '7D volume';
    }

    return '24H volume';
  }, [sortdOption]);

  const onClickCategory = useCallback(
    (category: DataCategories) => {
      dispatch(
        updateFilterAction({
          categories: [category.name],
          blockNumber: undefined,
          blockCategory: undefined,
          collectionIds: [],
        }),
      );
      dispatch(resetBlockCategoriesAction());
      history.push({
        pathname: `/${category.name.toLowerCase()}`,
        state: { isNftFilter: true },
      });
    },
    [history, dispatch],
  );

  const loadingTable = useMemo(() => {
    const array = [...new Array(10)].map((item) => 1);

    return array.map(() => {
      if (isMobile) {
        return (
          <Grid>
            <Grid className={classes.wrapLoading}>
              <Skeleton variant="circle" width={24} height={24} />
              <Skeleton variant="text" width={60} height={24} />
              <Skeleton variant="text" width={50} height={24} />
              <Skeleton variant="text" width={50} height={24} />
              <Skeleton variant="text" width={50} height={24} />
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid>
            <Grid className={classes.wrapLoading}>
              <Skeleton variant="circle" width={50} height={50} />
              <Skeleton variant="text" width={120} height={50} />
              <Skeleton variant="text" width={120} height={50} />
              <Skeleton variant="text" width={120} height={50} />
              <Skeleton variant="text" width={120} height={50} />
              <Skeleton variant="text" width={120} height={50} />
            </Grid>
          </Grid>
        );
      }
    });
  }, [isMobile, classes]);

  const dataTableCols: Array<
    DataTableColumn<DataCategories> & { label: string }
  > = useMemo(() => {
    const options: Array<DataTableColumn<DataCategories> & { label: string }> =
      [
        {
          name: CategoriesFieldEnum.imgUrl,
          label: '',
          options: {
            sort: true,
            setCellHeaderProps: () => ({
              className: clsx(classes.tableTitle, classes.avatarSection),
            }),
            customHeaderRender: () => (
              <div className={classes.titleImg}>/...</div>
            ),
            customBodyRender: (url, nft) => (
              <div
                className={classes.imgCategories}
                onClick={() => onClickCategory(nft)}
              >
                <Avatar src={url} />
              </div>
            ),
          },
        },
        {
          name: CategoriesFieldEnum.name,
          label: '',
          options: {
            sort: true,
            setCellHeaderProps: () => ({
              className: clsx(classes.tableTitle, classes.nameSection),
            }),
            customHeaderRender: () => <></>,
            customBodyRender: (name, nft) => (
              <div
                className={classes.wrapNameCategories}
                onClick={() => onClickCategory(nft)}
              >
                <Tooltip title={name}>
                  <Typography>/{name}</Typography>
                </Tooltip>
                <div className={classes.countCategories}>
                  {nft.totalItem.value}
                </div>
              </div>
            ),
          },
        },
        {
          name: CategoriesFieldEnum.totalOwner,
          label: CategoriesLabelEnum.OWNERS,
          options: {
            sort: true,
            setCellHeaderProps: () => ({
              className: clsx(classes.tableTitle, classes.ownersSection),
            }),
            customBodyRender: (totalOwner: any) => (
              <div className={classes.wrapItem}>
                <Tooltip title={totalOwner.value}>
                  <Typography style={{ position: 'relative' }}>
                    {totalOwner.value}
                    <span className={classes.status}>
                      {renderIconChange(totalOwner.status)}
                    </span>
                  </Typography>
                </Tooltip>
              </div>
            ),
          },
        },
        {
          name: CategoriesFieldEnum.firstPlace,
          label: CategoriesLabelEnum.FIRST_PLACE,
          options: {
            sort: true,
            setCellHeaderProps: () => ({
              className: clsx(classes.tableTitle, classes.firstPlaceSection),
            }),
            customBodyRender: (firstPlace) => (
              <div className={classes.wrapItem}>
                <Tooltip title={`$${firstPlace.value?.toFixed(2)}`}>
                  <Typography className={classes.textItemData}>
                    ${firstPlace.value?.toFixed(2)}
                    <span className={classes.status}>
                      {renderIconChange(firstPlace.status)}
                    </span>
                  </Typography>
                </Tooltip>
              </div>
            ),
          },
        },
        {
          name: CategoriesFieldEnum.lastPlace,
          label: CategoriesLabelEnum.LAST_PLACE,
          options: {
            sort: true,
            setCellHeaderProps: () => ({
              className: clsx(classes.tableTitle, classes.lastPlaceSection),
            }),
            customBodyRender: (lastPlace) => (
              <div className={classes.wrapItem}>
                <Tooltip title={`$${lastPlace.value?.toFixed(2)}`}>
                  <Typography className={classes.textItemData}>
                    ${lastPlace.value?.toFixed(2)}
                    <span className={classes.status}>
                      {renderIconChange(lastPlace.status)}
                    </span>
                  </Typography>
                </Tooltip>
              </div>
            ),
          },
        },
        {
          name: fieldVolumn,
          label: renderTitleTable,
          options: {
            sort: true,
            setCellHeaderProps: () => ({
              className: clsx(classes.tableTitle, classes.volumeSection),
            }),
            customBodyRender: (allTime) => {
              const allTimeValue =
                allTime.value < 0.0001 && allTime.value > 0
                  ? '<0.0001'
                  : allTime.value?.toFixed(4);
              return (
                <div className={classes.wrapItem}>
                  <Tooltip title={`E${allTime.value?.toFixed(4)}`}>
                    <Typography className={classes.textItemData}>
                      <EthIconNew width={10} height={10} />
                      {allTimeValue}
                      <span className={classes.status}>
                        {renderIconChange(allTime.status)}
                      </span>
                    </Typography>
                  </Tooltip>
                </div>
              );
            },
          },
        },
      ];

    return isMobile
      ? options.filter((item) => item.name !== CategoriesFieldEnum.totalOwner)
      : options;
  }, [
    classes,
    isMobile,
    renderTitleTable,
    fieldVolumn,
    renderIconChange,
    onClickCategory,
  ]);

  const dataTableOptions = useMemo<DataTableOptions<any>>(
    () => ({
      count,
      page,
      expandableRows: false,
      expandableRowsOnClick: false,
      rowsPerPage,
      rowsPerPageOptions: [10, 20, 30],
      orderBy: sortField,
      orderDirection: sort,
      pagination: false,
      setCellHeaderExpandProps: () => ({
        className: classes.tableTitleExpandRow,
      }),
    }),
    [classes, count, page, rowsPerPage, sortField, sort],
  );

  const buttonLoadMore = useMemo(() => {
    if (data.length < count) {
      return (
        <Grid className={classes.wrapButtonLoad}>
          <div onClick={() => onChangePage(true)} className={classes.button}>
            Load more
          </div>
        </Grid>
      );
    } else {
      return <></>;
    }
  }, [data, count, classes, onChangePage]);

  return (
    <Grid className={classes.root} lg={12}>
      <Grid className={classes.wrapTitleTop}>
        <Grid className={classes.textTopCategories}>Top categories</Grid>
        <Grid className={classes.sortOptionContainer}>
          <div className={classes.sortLabelContainer}>
            <Typography className={classes.sortLabel}>Sort by</Typography>
          </div>
          <div>
            <MenuComponent
              options={categoriesSortOptions}
              selectedOption={sortdOption}
              className={classes.sortOption}
              onChange={handleChangeSortOption}
            />
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.wrapTableCategories}>
        <DataTable
          data={data}
          columns={dataTableCols}
          options={dataTableOptions}
          tableClassName={classes.table}
          loading={loading}
          handleSort={handleSort}
        />
      </Grid>
      <Grid>{loading && loadingTable}</Grid>
      {buttonLoadMore}
    </Grid>
  );
}
