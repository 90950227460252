const colors = {
  pureWhite: '#FFFFFF',
  black1: '#000000',
  black2: '#100113',
  black3: '#1C0222',
  purple1: '#6F6BC5',
  yellow1: '#DDE542',
};

export default colors;
