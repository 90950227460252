import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function LogoIcon({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 220 60"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M9.30991 0L2.74316 20.2774L10.9447 26.1304L18.0102 18.086L9.30991 0Z"
        fill={color || '#65AEBF'}
      />
      <path
        d="M24.5494 20.4716L22.8315 13.3703L9.31006 0L18.0103 18.086L24.5494 20.4716Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M19.562 34.0083L24.5494 20.4715L18.0103 18.0859L10.9448 26.1303L19.562 34.0083Z"
        fill={color || '#3F6F93'}
      />
      <path
        d="M2.74316 20.2773L9.47616 27.1012L19.5618 34.0083L10.9447 26.1303L2.74316 20.2773Z"
        fill={color || '#0F3A44'}
      />
      <path
        d="M63.6446 20.9154C64.8361 22.1081 65.4456 23.9112 65.4456 26.2968V35.6726H61.622V26.7961C61.622 25.3536 61.3172 24.2995 60.6799 23.5783C60.0426 22.8571 59.1283 22.4965 57.9645 22.4965C56.69 22.4965 55.6371 22.9126 54.889 23.7725C54.1131 24.6324 53.7252 25.8252 53.7252 27.4063V35.7004H49.9016V26.8238C49.9016 25.3814 49.5968 24.3273 48.9595 23.6061C48.3222 22.8848 47.4078 22.5242 46.2441 22.5242C44.9419 22.5242 43.9167 22.9403 43.1408 23.7725C42.365 24.6047 42.0048 25.8252 42.0048 27.4063V35.7004H38.1812V19.3065H41.8109V21.3869C42.4204 20.638 43.1963 20.0832 44.1106 19.6948C45.025 19.3065 46.0502 19.1123 47.1585 19.1123C48.3776 19.1123 49.4582 19.3342 50.428 19.8058C51.3978 20.2774 52.1182 20.9431 52.6723 21.8308C53.3373 20.9708 54.224 20.3051 55.2769 19.8335C56.3298 19.362 57.5212 19.1123 58.7958 19.1123C60.8184 19.1123 62.4532 19.7226 63.6446 20.9154Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M70.5164 15.9497C70.0454 15.5059 69.8237 14.9788 69.8237 14.3131C69.8237 13.6751 70.0454 13.1203 70.5164 12.6765C70.9875 12.2327 71.5693 12.0107 72.262 12.0107C72.9547 12.0107 73.5366 12.2327 74.0076 12.6487C74.4787 13.0648 74.7003 13.5919 74.7003 14.2299C74.7003 14.8956 74.4787 15.4781 74.0076 15.922C73.5643 16.3935 72.9547 16.6155 72.262 16.6155C71.5693 16.6155 70.9875 16.3935 70.5164 15.9497ZM70.3225 19.3062H74.1462V35.6446H70.3225V19.3062Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M93.3752 20.9154C94.6221 22.1081 95.2317 23.9112 95.2317 26.269V35.6449H91.408V26.7683C91.408 25.3259 91.0755 24.2718 90.4105 23.5506C89.7455 22.8294 88.7757 22.4688 87.5289 22.4688C86.1158 22.4688 85.0075 22.8848 84.2039 23.7448C83.4004 24.6047 82.9848 25.7975 82.9848 27.4063V35.6726H79.1611V19.3065H82.7908V21.4147C83.4281 20.6657 84.2039 20.0832 85.1737 19.6948C86.1435 19.3065 87.2241 19.1123 88.4155 19.1123C90.4936 19.1123 92.1284 19.7226 93.3752 20.9154Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M109.917 34.7571C109.474 35.1177 108.92 35.3951 108.282 35.5892C107.645 35.7834 106.952 35.8666 106.26 35.8666C104.459 35.8666 103.073 35.3951 102.103 34.4519C101.134 33.5088 100.635 32.1496 100.635 30.3465V22.4686H97.9473V19.4173H100.635V15.7002H104.459V19.4173H108.836V22.4686H104.459V30.2356C104.459 31.04 104.653 31.6503 105.04 32.0663C105.428 32.4824 105.983 32.7044 106.731 32.7044C107.59 32.7044 108.31 32.4824 108.864 32.0386L109.917 34.7571Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M127.788 28.6822H114.987C115.209 29.8749 115.791 30.8458 116.733 31.5393C117.675 32.2328 118.866 32.5934 120.252 32.5934C122.053 32.5934 123.521 32.0109 124.685 30.8181L126.735 33.1759C126.015 34.0636 125.073 34.7293 123.965 35.1731C122.856 35.617 121.582 35.8389 120.169 35.8389C118.368 35.8389 116.788 35.4783 115.431 34.7571C114.073 34.0358 113.02 33.0372 112.272 31.7612C111.524 30.4852 111.164 29.0428 111.164 27.4339C111.164 25.8528 111.524 24.4103 112.244 23.1343C112.965 21.8583 113.962 20.8597 115.264 20.1385C116.539 19.4173 118.007 19.0566 119.614 19.0566C121.194 19.0566 122.635 19.4173 123.881 20.1107C125.128 20.8042 126.098 21.8028 126.818 23.0788C127.539 24.3548 127.871 25.825 127.871 27.4894C127.844 27.8222 127.816 28.2106 127.788 28.6822ZM116.483 23.2453C115.652 23.9665 115.126 24.9374 114.932 26.1301H124.186C124.02 24.9374 123.521 23.9942 122.69 23.2453C121.859 22.4963 120.806 22.1357 119.587 22.1357C118.368 22.1634 117.342 22.524 116.483 23.2453Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M147.489 12.9268V35.6175H143.831V33.5093C143.194 34.286 142.418 34.8685 141.504 35.2568C140.562 35.6452 139.564 35.8394 138.428 35.8394C136.849 35.8394 135.436 35.5065 134.189 34.7853C132.942 34.0641 131.945 33.0932 131.252 31.8449C130.559 30.5967 130.171 29.0987 130.171 27.4621C130.171 25.8255 130.532 24.3553 131.252 23.0793C131.972 21.8033 132.942 20.8324 134.189 20.139C135.436 19.4455 136.849 19.0849 138.428 19.0849C139.509 19.0849 140.506 19.279 141.393 19.6397C142.28 20.0003 143.056 20.5551 143.693 21.304V12.9268H147.489ZM141.338 31.9559C142.058 31.5398 142.64 30.9295 143.083 30.1806C143.499 29.4039 143.721 28.5162 143.721 27.4899C143.721 26.4635 143.499 25.5759 143.083 24.7992C142.668 24.0225 142.086 23.4399 141.338 23.0238C140.617 22.6078 139.786 22.3858 138.872 22.3858C137.957 22.3858 137.126 22.6078 136.406 23.0238C135.685 23.4399 135.103 24.0502 134.66 24.7992C134.244 25.5759 134.023 26.4635 134.023 27.4899C134.023 28.5162 134.244 29.4039 134.66 30.1806C135.076 30.9573 135.658 31.5398 136.406 31.9559C137.126 32.372 137.957 32.5939 138.872 32.5939C139.786 32.5661 140.617 32.372 141.338 31.9559Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M168.685 19.3065V33.1761C168.685 38.9181 165.748 41.803 159.874 41.803C158.295 41.803 156.826 41.6088 155.413 41.2205C154 40.8321 152.836 40.2496 151.922 39.5006L153.64 36.6158C154.36 37.1983 155.247 37.6699 156.355 38.0305C157.436 38.3911 158.544 38.5575 159.68 38.5575C161.481 38.5575 162.783 38.1414 163.615 37.337C164.446 36.5326 164.861 35.2843 164.861 33.6199V32.7323C164.224 33.4535 163.421 33.9805 162.479 34.3412C161.536 34.7018 160.511 34.8959 159.403 34.8959C157.851 34.8959 156.466 34.5631 155.191 33.8973C153.944 33.2316 152.947 32.2884 152.227 31.0957C151.506 29.9029 151.146 28.5159 151.146 26.9625C151.146 25.4091 151.506 24.0499 152.227 22.8571C152.947 21.6643 153.944 20.7489 155.191 20.0832C156.438 19.4174 157.851 19.1123 159.403 19.1123C160.594 19.1123 161.647 19.3065 162.617 19.6948C163.587 20.0832 164.39 20.6657 165.055 21.4701V19.3065H168.685ZM163.559 30.3467C164.473 29.4868 164.944 28.3495 164.944 26.9625C164.944 25.6033 164.473 24.4937 163.559 23.6338C162.645 22.7739 161.453 22.3578 160.013 22.3578C158.544 22.3578 157.353 22.7739 156.41 23.6338C155.496 24.4937 155.025 25.6033 155.025 26.9625C155.025 28.3495 155.496 29.4868 156.41 30.3467C157.325 31.2066 158.544 31.6504 160.013 31.6504C161.426 31.6504 162.617 31.2344 163.559 30.3467Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M188.995 28.6822H176.194C176.415 29.8749 176.997 30.8458 177.939 31.5393C178.881 32.2328 180.073 32.5934 181.458 32.5934C183.259 32.5934 184.728 32.0109 185.892 30.8181L187.942 33.1759C187.194 34.0636 186.279 34.7293 185.171 35.1731C184.063 35.617 182.788 35.8389 181.375 35.8389C179.574 35.8389 177.995 35.4783 176.637 34.7571C175.279 34.0358 174.227 33.0372 173.478 31.7612C172.73 30.4852 172.37 29.0428 172.37 27.4339C172.37 25.8528 172.73 24.4103 173.451 23.1343C174.171 21.8583 175.169 20.8597 176.471 20.1385C177.773 19.4173 179.186 19.0566 180.821 19.0566C182.4 19.0566 183.841 19.4173 185.088 20.1107C186.335 20.8042 187.305 21.8028 188.025 23.0788C188.718 24.3548 189.078 25.825 189.078 27.4894C189.05 27.8222 189.023 28.2106 188.995 28.6822ZM177.69 23.2453C176.859 23.9665 176.332 24.9374 176.138 26.1301H185.393C185.227 24.9374 184.728 23.9942 183.897 23.2453C183.065 22.4963 182.012 22.1357 180.793 22.1357C179.574 22.1634 178.549 22.524 177.69 23.2453Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M218.199 20.9154C219.39 22.1081 220 23.9112 220 26.2968V35.6726H216.176V26.7961C216.176 25.3536 215.871 24.2995 215.234 23.5783C214.597 22.8571 213.71 22.4965 212.519 22.4965C211.244 22.4965 210.219 22.9126 209.443 23.7725C208.667 24.6324 208.279 25.8252 208.279 27.4063V35.7004H204.456V26.8238C204.456 25.3814 204.151 24.3273 203.514 23.6061C202.876 22.8848 201.99 22.5242 200.798 22.5242C199.496 22.5242 198.471 22.9403 197.695 23.7725C196.919 24.6047 196.559 25.8252 196.559 27.4063V35.7004H192.735V19.3065H196.365V21.3869C196.975 20.638 197.75 20.0832 198.665 19.6948C199.579 19.3065 200.604 19.1123 201.713 19.1123C202.932 19.1123 204.012 19.3342 204.982 19.8058C205.952 20.2774 206.672 20.9431 207.227 21.8308C207.892 20.9708 208.75 20.3051 209.831 19.8335C210.884 19.362 212.048 19.1123 213.35 19.1123C215.373 19.1123 217.007 19.7226 218.199 20.9154Z"
        fill={color || '#1C0222'}
      />
      <path
        d="M42.2268 47.8506V57.3374H40.4535V49.3208H38.4585V47.8506H42.2268Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M46.0226 56.9208C45.4407 56.5324 44.9419 55.9776 44.6095 55.2287C44.277 54.4797 44.083 53.6198 44.083 52.5934C44.083 51.5671 44.2493 50.7072 44.6095 49.9582C44.9697 49.2093 45.413 48.6545 46.0226 48.2661C46.6044 47.8778 47.2971 47.6836 48.0452 47.6836C48.7933 47.6836 49.486 47.8778 50.0956 48.2661C50.7052 48.6545 51.1762 49.2093 51.5087 49.9582C51.8412 50.7072 52.0351 51.5671 52.0351 52.5934C52.0351 53.6198 51.8689 54.4797 51.5087 55.2287C51.1485 55.9776 50.7052 56.5324 50.0956 56.9208C49.486 57.3091 48.821 57.5033 48.0452 57.5033C47.2694 57.5033 46.6321 57.3091 46.0226 56.9208ZM49.68 55.1177C50.0679 54.5629 50.2618 53.7308 50.2618 52.5934C50.2618 51.4839 50.0679 50.6517 49.68 50.0692C49.2921 49.4866 48.7379 49.237 48.0729 49.237C47.4079 49.237 46.8815 49.5144 46.4659 50.0692C46.0503 50.624 45.884 51.4561 45.884 52.5934C45.884 53.703 46.078 54.5352 46.4659 55.1177C46.8538 55.7002 47.3802 55.9499 48.0729 55.9499C48.7379 55.9499 49.2921 55.6725 49.68 55.1177Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M55.1661 56.9208C54.5842 56.5324 54.0855 55.9776 53.753 55.2287C53.4205 54.4797 53.2266 53.6198 53.2266 52.5934C53.2266 51.5671 53.3928 50.7072 53.753 49.9582C54.1132 49.2093 54.5565 48.6545 55.1661 48.2661C55.748 47.8778 56.4407 47.6836 57.1888 47.6836C57.9369 47.6836 58.6296 47.8778 59.2392 48.2661C59.8487 48.6545 60.3198 49.2093 60.6522 49.9582C60.9847 50.7072 61.1787 51.5671 61.1787 52.5934C61.1787 53.6198 61.0124 54.4797 60.6522 55.2287C60.292 55.9776 59.8487 56.5324 59.2392 56.9208C58.6296 57.3091 57.9646 57.5033 57.1888 57.5033C56.413 57.5033 55.748 57.3091 55.1661 56.9208ZM58.7958 55.1177C59.1837 54.5629 59.3777 53.7308 59.3777 52.5934C59.3777 51.4839 59.1837 50.6517 58.7958 50.0692C58.4079 49.4866 57.8538 49.237 57.1888 49.237C56.5238 49.237 55.9973 49.5144 55.5817 50.0692C55.1661 50.624 54.9999 51.4561 54.9999 52.5934C54.9999 53.703 55.1938 54.5352 55.5817 55.1177C55.9696 55.7002 56.4961 55.9499 57.1888 55.9499C57.8815 55.9499 58.4079 55.6725 58.7958 55.1177Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M65.5568 47.8506V57.3374H63.7835V49.3208H61.7886V47.8506H65.5568Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M72.7051 57.171C72.0124 56.9491 71.4859 56.6994 71.0703 56.3388L71.6799 54.9796C72.0678 55.2847 72.5388 55.5344 73.1207 55.7286C73.6748 55.9227 74.2567 56.0337 74.8109 56.0337C75.5036 56.0337 76.03 55.9227 76.3902 55.7008C76.7504 55.4789 76.9167 55.1738 76.9167 54.8132C76.9167 54.5358 76.8058 54.3139 76.6396 54.1474C76.4456 53.981 76.1963 53.8423 75.8915 53.7313C75.5867 53.6204 75.1988 53.5094 74.6723 53.3985C73.9519 53.232 73.3701 53.0656 72.9267 52.8714C72.4834 52.6772 72.0955 52.4276 71.763 52.067C71.4305 51.7064 71.292 51.2071 71.292 50.5691C71.292 50.042 71.4305 49.5704 71.7076 49.1266C71.9847 48.6828 72.428 48.3499 72.9822 48.1003C73.564 47.8506 74.2567 47.7119 75.0879 47.7119C75.6698 47.7119 76.224 47.7951 76.8058 47.9338C77.36 48.0725 77.8587 48.2944 78.2466 48.5441L77.6925 49.9033C77.2769 49.6537 76.8335 49.4595 76.3902 49.3485C75.9469 49.2098 75.5036 49.1544 75.0602 49.1544C74.3675 49.1544 73.8411 49.2653 73.5086 49.515C73.1761 49.7646 73.0099 50.0698 73.0099 50.4581C73.0099 50.7355 73.1207 50.9574 73.2869 51.1238C73.4809 51.2903 73.7303 51.429 74.0351 51.5399C74.3398 51.6509 74.7277 51.7618 75.2542 51.8728C75.9469 52.0392 76.5288 52.2057 76.9998 52.3721C77.4708 52.5386 77.831 52.8159 78.1635 53.1766C78.496 53.5372 78.6345 54.0365 78.6345 54.6467C78.6345 55.1738 78.496 55.6454 78.2189 56.0614C77.9418 56.4775 77.4985 56.8381 76.9167 57.0878C76.3348 57.3374 75.6421 57.4761 74.8109 57.4761C74.0905 57.5039 73.3701 57.3929 72.7051 57.171Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M87.3074 50.0967V59.9719H85.6172V56.449C85.0353 57.1147 84.2872 57.4199 83.2898 57.4199C82.5971 57.4199 81.9598 57.2534 81.4056 56.9483C80.8515 56.6432 80.4081 56.1993 80.1034 55.6446C79.7986 55.0898 79.6323 54.424 79.6323 53.6751C79.6323 52.9538 79.7986 52.2881 80.1034 51.7333C80.4081 51.1785 80.8515 50.7347 81.4056 50.4296C81.9598 50.1244 82.5971 49.958 83.2898 49.958C83.7885 49.958 84.2318 50.0412 84.6474 50.2077C85.0631 50.3741 85.3956 50.6515 85.6726 50.9844V50.0412L87.3074 50.0967ZM85.0353 55.3672C85.451 54.9511 85.6449 54.3963 85.6449 53.7305C85.6449 53.0648 85.451 52.51 85.0353 52.0939C84.6197 51.6778 84.121 51.4837 83.4837 51.4837C82.8464 51.4837 82.3477 51.7056 81.9598 52.0939C81.5442 52.51 81.3502 53.0648 81.3502 53.7305C81.3502 54.3963 81.5442 54.9511 81.9598 55.3672C82.3754 55.7833 82.8741 55.9774 83.4837 55.9774C84.121 56.0052 84.6474 55.7833 85.0353 55.3672Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M96.5616 50.0967V57.3366H94.9545V56.4212C94.6775 56.7541 94.345 57.0038 93.9293 57.1702C93.5137 57.3366 93.0981 57.4198 92.5994 57.4198C91.6296 57.4198 90.8815 57.1425 90.3273 56.6154C89.7732 56.0884 89.4961 55.2839 89.4961 54.2298V50.0967H91.1863V54.0079C91.1863 54.6737 91.3248 55.1452 91.6296 55.4781C91.9344 55.811 92.35 55.9497 92.8764 55.9497C93.486 55.9497 93.957 55.7555 94.3173 55.3949C94.6775 55.0065 94.8437 54.4795 94.8437 53.7583V50.0967H96.5616Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M103.988 50.791C104.542 51.2903 104.847 52.067 104.847 53.0933V57.3374H103.239V56.4498C103.018 56.7549 102.741 57.0046 102.353 57.171C101.965 57.3374 101.494 57.4206 100.967 57.4206C100.441 57.4206 99.9422 57.3374 99.5543 57.1432C99.1664 56.9491 98.8339 56.6994 98.6123 56.3665C98.3906 56.0337 98.2798 55.6731 98.2798 55.257C98.2798 54.5912 98.5291 54.0919 99.0002 53.7036C99.4712 53.3152 100.247 53.1211 101.272 53.1211H103.156V53.0101C103.156 52.5108 103.018 52.1224 102.713 51.845C102.408 51.5677 101.965 51.429 101.355 51.429C100.94 51.429 100.552 51.4844 100.164 51.6231C99.776 51.7618 99.4435 51.9283 99.1664 52.1502L98.5014 50.9297C98.8894 50.6523 99.3327 50.4303 99.8591 50.2639C100.386 50.0975 100.967 50.042 101.549 50.042C102.602 50.042 103.433 50.2916 103.988 50.791ZM102.436 55.9227C102.768 55.7285 102.99 55.4512 103.129 55.0905V54.2584H101.383C100.413 54.2584 99.9145 54.5912 99.9145 55.2292C99.9145 55.5344 100.025 55.784 100.275 55.9505C100.524 56.1169 100.857 56.2278 101.3 56.2278C101.743 56.2278 102.103 56.1169 102.436 55.9227Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M111.191 50.042V51.6509C111.053 51.6231 110.914 51.6231 110.803 51.6231C110.138 51.6231 109.64 51.8173 109.252 52.2057C108.864 52.594 108.697 53.1488 108.697 53.87V57.3929H107.007V50.153H108.614V51.207C109.113 50.4026 109.972 50.042 111.191 50.042Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M119.337 54.2857H113.657C113.768 54.8127 114.017 55.2288 114.433 55.5617C114.849 55.8668 115.375 56.0333 115.985 56.0333C116.788 56.0333 117.425 55.7836 117.952 55.2566L118.866 56.3107C118.534 56.699 118.118 57.0041 117.619 57.1983C117.121 57.3925 116.567 57.5035 115.929 57.5035C115.126 57.5035 114.433 57.337 113.823 57.0319C113.214 56.7268 112.743 56.2829 112.41 55.7004C112.078 55.1456 111.912 54.4799 111.912 53.7864C111.912 53.0929 112.078 52.4549 112.383 51.8724C112.715 51.3176 113.158 50.8738 113.713 50.5409C114.294 50.2358 114.932 50.0693 115.652 50.0693C116.345 50.0693 116.982 50.2358 117.536 50.5409C118.09 50.846 118.534 51.2899 118.839 51.8724C119.143 52.4272 119.31 53.0929 119.31 53.8419C119.365 53.8974 119.365 54.0638 119.337 54.2857ZM114.322 51.8724C113.934 52.2053 113.713 52.6213 113.63 53.1484H117.758C117.675 52.6213 117.453 52.2053 117.093 51.8724C116.733 51.5395 116.262 51.3731 115.708 51.3731C115.153 51.3731 114.71 51.5395 114.322 51.8724Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M121.499 57.2257C120.945 57.0592 120.501 56.8651 120.141 56.6431L120.806 55.3671C121.139 55.5891 121.526 55.7555 121.97 55.8942C122.413 56.0329 122.884 56.0884 123.327 56.0884C124.325 56.0884 124.851 55.811 124.851 55.2839C124.851 55.0343 124.713 54.8401 124.464 54.7569C124.214 54.6459 123.799 54.5627 123.217 54.4795C122.635 54.3963 122.136 54.2853 121.748 54.1743C121.36 54.0634 121.055 53.8415 120.778 53.5641C120.501 53.259 120.363 52.8706 120.363 52.3436C120.363 51.6501 120.64 51.0953 121.222 50.7069C121.804 50.2909 122.579 50.0967 123.549 50.0967C124.048 50.0967 124.547 50.1522 125.045 50.2631C125.544 50.3741 125.96 50.5128 126.265 50.7069L125.6 51.9829C124.99 51.6223 124.297 51.4282 123.521 51.4282C123.023 51.4282 122.635 51.5114 122.385 51.6501C122.136 51.7888 121.998 51.9829 121.998 52.2326C121.998 52.51 122.136 52.7042 122.413 52.8151C122.69 52.9261 123.106 53.037 123.688 53.1203C124.27 53.2035 124.741 53.3144 125.101 53.4254C125.461 53.5363 125.793 53.7305 126.071 54.0079C126.348 54.2853 126.458 54.6737 126.458 55.2007C126.458 55.8664 126.154 56.4212 125.572 56.8373C124.99 57.2534 124.186 57.4476 123.161 57.4476C122.635 57.4476 122.081 57.3644 121.499 57.2257Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M132.997 56.9762C132.416 56.6711 131.945 56.2273 131.612 55.6447C131.28 55.09 131.113 54.4242 131.113 53.7307C131.113 53.0095 131.28 52.3715 131.612 51.8167C131.945 51.2619 132.416 50.8181 132.997 50.4852C133.579 50.1801 134.244 50.0137 134.965 50.0137C135.713 50.0137 136.378 50.1801 136.96 50.4852C137.541 50.7904 138.013 51.2342 138.345 51.8167C138.677 52.3715 138.844 53.0095 138.844 53.7307C138.844 54.4519 138.677 55.09 138.345 55.6447C138.013 56.1995 137.541 56.6433 136.96 56.9762C136.378 57.3091 135.713 57.4478 134.965 57.4478C134.244 57.4478 133.579 57.3091 132.997 56.9762ZM136.516 55.3673C136.932 54.9513 137.126 54.3965 137.126 53.7307C137.126 53.065 136.932 52.5102 136.516 52.0941C136.101 51.678 135.602 51.4839 134.965 51.4839C134.327 51.4839 133.829 51.7058 133.441 52.0941C133.025 52.5102 132.831 53.065 132.831 53.7307C132.831 54.3965 133.025 54.9513 133.441 55.3673C133.856 55.7834 134.355 55.9776 134.965 55.9776C135.574 55.9776 136.101 55.7834 136.516 55.3673Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M142.335 49.7362V50.18H144.33V51.5392H142.39V57.3645H140.7V51.5392H139.509V50.18H140.7V49.7084C140.7 48.9317 140.922 48.3215 141.393 47.8777C141.836 47.4338 142.474 47.2119 143.305 47.2119C143.942 47.2119 144.468 47.3506 144.829 47.6003L144.358 48.8763C144.081 48.6821 143.748 48.5711 143.416 48.5711C142.695 48.5711 142.335 48.9595 142.335 49.7362Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M157.796 47.8506V57.3374H156.355L151.118 50.9019V57.3374H149.373V47.8506H150.813L156.05 54.2861V47.8506H157.796Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M162.119 49.3481V52.233H166.69V53.7309H162.119V57.3648H160.345V47.8779H167.272V49.3481H162.119Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M170.985 49.3485H167.854V47.8506H175.917V49.3485H172.786V57.3652H171.013L170.985 49.3485Z"
        fill={color || '#6F6BC5'}
      />
      <path
        d="M24.2166 19.085L24.5491 20.4719L19.5617 34.0087L9.47607 27.1016L2.74307 20.2777L3.13098 19.085H0V35.6453H27.2922V19.085H24.2166Z"
        fill={color || '#1C0222'}
      />
    </SvgIcon>
  );
}

export default memo(LogoIcon);
