import React from 'react';
import { useStyle } from './styles';

interface TermContentProps {
  title: string;
  content?: string;
  className?: string;
  children?: any;
}
function TermContent(props: TermContentProps) {
  const classes = useStyle();
  const { content, title, children } = props;
  return (
    <div
      className={classes.container}
      id={title === '9. Privacy Policy' ? title : undefined}
    >
      <p className={classes.title}>{title}</p>
      {children || <span className={classes.content}>{content}</span>}
    </div>
  );
}

export default TermContent;
