import React, { useCallback, useEffect, useMemo } from 'react';
import PageHome from 'components/PageHome';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTableCategoriesAction,
  getAllCategoriesAction,
} from 'store/actions/categoriesActions';
import {
  getAllCategories,
  getTableCategories,
  sTaskStatus,
} from 'store/selectors';
import { CategoriesActionTypeEnum } from 'enums/actions';
import { SortEnum } from 'enums/sortEnum';
import { CategoriesLabelEnum, ParamsSortBy } from 'enums/categories';
import useTitle from 'hooks/useTitle';
import Footer from 'components/AddNFT/AddNFTFooter';

export default function PageHomeContainer() {
  const dispatch = useDispatch();
  const allCategories = useSelector(getAllCategories);
  const tableCategories = useSelector(getTableCategories);
  const status = useSelector(
    sTaskStatus(CategoriesActionTypeEnum.GET_ALL_CATEGORIES),
  );

  const renderColumnSort = useMemo(
    () => (value?: CategoriesLabelEnum) => {
      if (value === CategoriesLabelEnum.FIRST_PLACE) {
        return 'FirstPlace';
      } else if (value === CategoriesLabelEnum.LAST_PLACE) {
        return 'LastPlace';
      } else if (value === CategoriesLabelEnum.OWNERS) {
        return 'Owners';
      } else if (value === CategoriesLabelEnum.ALL_TIME) {
        return 'VolumeAll';
      } else if (value === CategoriesLabelEnum.LAST_24_HOURS) {
        return 'Volume24h';
      } else if (value === CategoriesLabelEnum.LAST_7_DAYS) {
        return 'Vol7d';
      } else if (value === CategoriesLabelEnum.LAST_30_DAYS) {
        return 'Vol30d';
      }
    },
    [],
  );

  const handleGetAllCategories = useCallback(() => {
    dispatch(
      getAllCategoriesAction({
        includeFirstPlaceImg: true,
        includeIcon: true,
        sortType: SortEnum.Desc,
        sortBy: ParamsSortBy.ALL_TIME,
      }),
    );
  }, [dispatch]);

  const handleGetTableCategories = useCallback(() => {
    dispatch(
      getTableCategoriesAction({
        includeIcon: true,
        sortType: SortEnum.Desc,
        sortBy: ParamsSortBy.ALL_TIME,
      }),
    );
  }, [dispatch]);

  // trigger by loadmore button is click
  const onChangePage = () => null;

  // change duration time
  const handleChangeDuration = useCallback(
    (value: ParamsSortBy) => {
      dispatch(
        getTableCategoriesAction({
          includeIcon: true,
          sortType: SortEnum.Desc,
          sortBy: value,
        }),
      );
    },
    [dispatch],
  );

  // change sort column and sort direction
  const handleSort = useCallback(
    (sortType: SortEnum, column?: CategoriesLabelEnum) => {
      dispatch(
        getTableCategoriesAction({
          sortType,
          includeIcon: true,
          sortBy: renderColumnSort(column),
        }),
      );
    },
    [dispatch, renderColumnSort],
  );

  useEffect(() => {
    handleGetAllCategories();
  }, [handleGetAllCategories]);

  useEffect(() => {
    handleGetTableCategories();
  }, [handleGetTableCategories]);

  useTitle('Mintedgem - 1001 Squares of NFT');

  return (
    <>
      <PageHome
        allCategories={allCategories.data}
        categories={tableCategories.data}
        pageSize={tableCategories.pageSize}
        total={tableCategories.total}
        pageNumber={tableCategories.pageNumber}
        onChangePage={onChangePage}
        loading={status?.processing}
        handleChangeDuration={handleChangeDuration}
        sortType={tableCategories?.sortType}
        handleSort={handleSort}
        sortField={tableCategories.sortBy}
      />
      <Footer />
    </>
  );
}
