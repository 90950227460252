/* eslint-disable */
import React, { useEffect, useMemo } from 'react';
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TermContent from './TermContent';
import { contents } from './content';
import useTitle from 'hooks/useTitle';

function TermService() {
  const classes = useStyles();
  const history = useHistory<{ signal: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onRedirectHome = () => {
    history.push('/');
  };

  const onRedirectTermService = () => {
    history.push('/terms-of-service');
  };
  const state = history.location.state;

  useEffect(() => {
    const timerID = setTimeout(() => {
      if (state?.signal) {
        const contentNine = document.getElementById('9. Privacy Policy');
        if (contentNine) {
          contentNine.scrollIntoView({
            block: isMobile ? 'end' : 'start',
            behavior: 'smooth',
          });
        }
      }
    }, 0);
    return () => clearTimeout(timerID);
  }, [state, isMobile]);

  const contentSix = useMemo(() => {
    return (
      <TermContent title={'6. User Conduct'}>
        <div className={classes.six}>
          You agree to not violate any laws, contracts, intellectual property or
          other third-party rights, and that you are solely responsible for your
          conduct while accessing or using the Service. You agree to not:
        </div>
        <ul className={classes.six}>
          <li>
            Use or attempt to use third party Accounts without such party’s
            authorization
          </li>

          <li>
            Access the Service from an alternate blockchain address if your
            other blockchain addresses have been blocked from accessing the
            Service.
          </li>
          <li>Spam or send unwanted NFTs to other users.</li>
          <li>
            Use the Service in a way that could damage or impair the functioning
            of the Service in any manner;
          </li>
          <li>
            Use a robot, spider, crawler, scraper, script, browser extension, or
            other automated means to interact with the Service.
          </li>
          <li>Use the Service for money laundering or terrorist financing.</li>
          <li>
            Use the Service to engage in fraud, deceptive or misleading
            activities, or price manipulation.
          </li>
          <li>
            Use the Service to promote or facilitate the buying, selling, or
            transfer of fraudulently obtained or stolen items, and/or any other
            illegally obtained items
          </li>
          <li>
            Display or promote content which presents suicide, self-harm, hate
            or violence against others, or that doxes another person
          </li>
          <li>
            Display or promote illegal content, including (but not limited to)
            content containing child sexual exploitation
          </li>
          <li>
            Violate intellectual property rights or any other rights of others
          </li>
          <li>
            Use the Service in a manner that could interfere with other users
            fully enjoying the Service.
          </li>
        </ul>
        <div className={classes.six}>
          Posting, promoting, and displaying NSFW content is allowed on a
          case-to-case basis. We reserve the right, at our sole discretion, to
          take down content from the front page if we believe that it may make
          users feel uncomfortable, may be offensive, or we believe it
          interferes negatively with the experience of other users. NSFW content
          that is allowed may be handled differently than non-NSFW content in
          several instances of the front end interface. If you would like to
          check if a certain NSFW content will be allowed, contact us through
          official channels like the official 1001 Squares of NFT discord or by email at
          hello@1001squaresofnft.com.
        </div>
      </TermContent>
    );
  }, []);

  useTitle('Terms of Service | Mintedgem');

  return (
    <>
      <div className={classes.crumpet}>
        <span onClick={onRedirectHome}>Home · </span>
        <span
          onClick={onRedirectTermService}
          className={classes.crumpetTermserive}
        >
          Terms of Service
        </span>
      </div>
      <div className={classes.container}>
        <Typography className={classes.title}>Terms of Service</Typography>
        <div style={{ fontStyle: 'italic' }}>
          <span>Last updated on </span>
          <span style={{ fontWeight: 700 }}>06/07/22</span>
        </div>
        <div>
          {contents.map((content) =>
            content.title === '6. User Conduct' ? (
              contentSix
            ) : (
              <TermContent {...content} key={content.title} />
            ),
          )}
        </div>
      </div>
    </>
  );
}

export default TermService;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '200px 293px 153px 293px',
    [theme.breakpoints.down('md')]: {
      padding: '136px 24px 140px 24px',
    },
  },
  crumpet: {
    color: '#6F6BC5',
    cursor: 'pointer',
    fontWeight: 500,
    position: 'relative',
    marginLeft: 103,
    [theme.breakpoints.down('md')]: {
      top: '136px',
      marginLeft: 24,
      marginBottom: 20,
    },
    top: '180px',
  },
  crumpetTermserive: {
    fontWeight: 700,
  },
  six: {
    fontSize: 24,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  title: {
    fontSize: 42,
    fontWeight: 700,
    lineHeight: '58.8px',
    marginBottom: 12,
  },
}));
