import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
  forwardRef,
} from 'react';
import { VariableSizeGrid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useMediaQuery, useTheme, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { isMobileFn } from 'common/helper';
import { debounce } from 'lodash';
import { useStyles } from './style';
import CategoryItem from 'components/Category/CategoryItem';
import MenuComponent, { OptionItem } from 'components/common/MenuComponent';
import { CategorySortOptions } from 'enums/categories';
import { DataCategories } from 'types/categories';
import { getListCategoriesAction } from 'store/actions/listCategoriesAction';
import { useDispatch, useSelector } from 'react-redux';
import { getListCategories, loadedState, scrollState } from 'store/selectors';
import { updateAppStateAction } from 'store/actions/appActions';
import CategorySkeleton from 'components/Category/CategorySkeleton';
import { updateFilterAction } from 'store/actions/filterActions';
import { resetBlockCategoriesAction } from 'store/actions/blockCategoriesAction';
import { SortEnum } from 'enums/sortEnum';
import useTitle from 'hooks/useTitle';

const { ReactWindowScroller } = require('react-window-scroller');

const categoriesSortOptions: OptionItem[] = [
  {
    key: CategorySortOptions.TOTAL_ITEMS,
    label: 'TOTAL ITEMS',
  },
  {
    key: CategorySortOptions.TOTAL_OWNERS,
    label: 'TOTAL OWNERS',
  },
  {
    key: CategorySortOptions.TOTAL_COLLECTIONS,
    label: 'TOTAL COLLECTIONS',
  },
  {
    key: CategorySortOptions.FIRST_PLACE,
    label: 'FIRST PLACE',
  },
  {
    key: CategorySortOptions.LAST_PLACE,
    label: 'LAST PLACE',
  },
  {
    key: CategorySortOptions.ALL_TIME_VOLUME,
    label: 'ALL TIME VOLUME',
  },
  {
    key: CategorySortOptions.LAST_24_HOURS,
    label: 'LAST 24 HOURS',
  },
  {
    key: CategorySortOptions.FLOOR_PRICE,
    label: 'FLOOR PRICE',
  },
  {
    key: CategorySortOptions.TIME_LEFT,
    label: 'TIME LEFT',
  },
  {
    key: CategorySortOptions.ALPHABETICAL,
    label: 'ALPHABETICAL',
  },
];

const GUTTER_SIZE = 16;

const Categories = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const listCategories = useSelector(getListCategories);
  const isLoaded = useSelector(loadedState);
  const isScrolling = useSelector(scrollState);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md')) && !isDesktop;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [maxItemsPerRow, setMaxItemsPerRow] = useState(1);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const gridRef = useRef<any>();
  const infiniteLoaderRef = useRef<any>(null);

  const sortdOption = useMemo(() => {
    return categoriesSortOptions.find(
      (option) => option.key === listCategories.sortBy,
    );
  }, [listCategories.sortBy]);

  const hasMore = useMemo(
    () =>
      listCategories.pageNumber * listCategories.pageSize <
      listCategories.total,
    [listCategories],
  );

  useTitle('Categories | Mintedgem');
  const updateDimension = useCallback(() => {
    const isMobile = isMobileFn();
    setDimensions({
      height: isMobile ? window.outerHeight : window.innerHeight,
      width: isMobile ? window.outerWidth : window.innerWidth,
    });
  }, []);

  useEffect(() => {
    updateDimension();
    const debouncedHandleResize = debounce(function handleResize() {
      updateDimension();
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [updateDimension]);

  useEffect(() => {
    if (isDesktop) {
      setMaxItemsPerRow(3);
    } else if (isTablet) {
      setMaxItemsPerRow(2);
    } else {
      setMaxItemsPerRow(1);
    }
  }, [isDesktop, isTablet, setMaxItemsPerRow]);

  // props for InfiniteLoader component
  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasMore || index < listCategories.data.length;
    },
    [hasMore, listCategories.data.length],
  );

  const itemCount = useMemo(() => {
    return hasMore
      ? listCategories.data.length + 1
      : listCategories.data.length;
  }, [hasMore, listCategories.data.length]);

  const loadMoreItems = useCallback(() => {
    dispatch(
      getListCategoriesAction({
        pageNumber: listCategories.pageNumber + 1,
      }),
    );
  }, [listCategories.pageNumber, dispatch]);

  const renderWidthGrid = useMemo(() => {
    if (isDesktop) {
      const initWidth = dimensions.width - 64;
      return initWidth;
    } else if (isTablet) {
      const initWidth = dimensions.width - 16;
      return initWidth;
    }
    return dimensions.width - 32;
  }, [isDesktop, isTablet, dimensions.width]);

  const columnWidth = useCallback(() => {
    let margin = 0;
    if (isTablet) {
      margin = 32;
    } else if (isMobile) {
      margin = 0;
    }

    return (
      (renderWidthGrid - margin - (maxItemsPerRow - 1) * GUTTER_SIZE) /
      maxItemsPerRow
    );
  }, [renderWidthGrid, maxItemsPerRow, isTablet, isMobile]);

  const renderRowCount = useMemo(() => {
    const rowNumber = Math.ceil(listCategories.data.length / maxItemsPerRow);

    // number row of Skeleton
    if (!isLoaded) {
      if (isDesktop) {
        return 2;
      } else {
        return 3;
      }
    }

    if (hasMore) {
      return rowNumber + 2;
    }
    return rowNumber;
  }, [
    listCategories.data.length,
    maxItemsPerRow,
    hasMore,
    isLoaded,
    isDesktop,
  ]);

  const onClickCategory = useCallback(
    (category: DataCategories) => {
      dispatch(
        updateFilterAction({
          categories: [category.name],
          blockNumber: undefined,
          blockCategory: undefined,
          collectionIds: [],
        }),
      );
      dispatch(resetBlockCategoriesAction());
      history.push({ pathname: `/${category.name.toLowerCase()}` });
    },
    [history, dispatch],
  );

  const getCellStyle = useCallback(
    (columnIndex, style) => {
      return {
        ...style,
        left: Number(
          columnIndex === 0
            ? style.left
            : Number(style.left || 0) + columnIndex * GUTTER_SIZE,
        ),
        right: Number(
          columnIndex === maxItemsPerRow
            ? style.right
            : Number(style.right || 0) + columnIndex * GUTTER_SIZE,
        ),
        cursor: 'pointer',
      };
    },
    [maxItemsPerRow],
  );

  const Cell = useCallback(
    ({ columnIndex, rowIndex, style, data }: any) => {
      const index = rowIndex * maxItemsPerRow + columnIndex;
      const item = data[index] as DataCategories;
      const customStyle = getCellStyle(columnIndex, style);

      if (!isLoaded) {
        return (
          <div style={getCellStyle(columnIndex, style)}>
            <CategorySkeleton />
          </div>
        );
      }

      if (item) {
        return (
          <div
            style={getCellStyle(columnIndex, style)}
            onClick={() => onClickCategory(item)}
          >
            <CategoryItem
              data={item}
              index={index}
              widthOfColumn={customStyle?.width}
              sortOption={sortdOption!}
            />
          </div>
        );
      } else {
        if (!hasMore) return null;
        return (
          <div style={getCellStyle(columnIndex, style)}>
            <CategorySkeleton />
          </div>
        );
      }
    },
    [
      hasMore,
      maxItemsPerRow,
      isLoaded,
      sortdOption,
      getCellStyle,
      onClickCategory,
    ],
  );

  const loadFirstPageSuccess = useCallback(() => {
    dispatch(
      updateAppStateAction({
        isLoaded: true,
      }),
    );
  }, [dispatch]);

  const refreshPage = useCallback(
    ({
      sortBy,
      pageNumber,
      pageSize,
    }: {
      sortBy: CategorySortOptions;
      pageNumber: number;
      pageSize: number;
    }) => {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache();
      }

      dispatch(
        updateAppStateAction({
          isLoaded: false,
        }),
      );
      dispatch(
        getListCategoriesAction(
          {
            sortBy,
            pageNumber,
            pageSize,
            sortType:
              sortBy === CategorySortOptions.ALPHABETICAL
                ? SortEnum.Asc
                : SortEnum.Desc,
          },
          loadFirstPageSuccess,
        ),
      );
    },
    [dispatch, loadFirstPageSuccess],
  );

  useEffect(() => {
    refreshPage({
      sortBy: CategorySortOptions.ALL_TIME_VOLUME,
      pageNumber: 1,
      pageSize: 30,
    });
  }, [refreshPage, dispatch]);

  const handleChangeSortOption = useCallback(
    (option: OptionItem) => {
      refreshPage({
        sortBy: option.key as CategorySortOptions,
        pageNumber: 1,
        pageSize: 30,
      });
    },
    [refreshPage],
  );

  const newItemsRendered = useCallback(
    (onItemsRendered: any) => (gridData: any) => {
      const {
        overscanRowStartIndex,
        overscanRowStopIndex,
        overscanColumnStopIndex,
      } = gridData;

      const endCol = overscanColumnStopIndex + 1;

      const startRow = overscanRowStartIndex;
      const endRow = overscanRowStopIndex;

      const visibleStartIndex = startRow * endCol;
      const visibleStopIndex = endRow * endCol;

      onItemsRendered({
        visibleStartIndex,
        visibleStopIndex,
      } as any);
    },
    [],
  );

  const innerElementType = forwardRef(({ style, ...rest }: any, ref: any) => {
    return (
      <div
        ref={ref}
        style={{
          ...style,
          width: `${renderWidthGrid}px`,
        }}
        {...rest}
      />
    );
  });

  const renderHeightOfGrid = useMemo(() => {
    if (isDesktop) {
      return dimensions.height - 197;
    } else if (isTablet) {
      return dimensions.height - 176;
    }
    return window.innerHeight - 196;
  }, [isDesktop, isTablet, dimensions.height]);

  const rowHeight = useCallback(() => {
    const coulumnWith =
      (renderWidthGrid - 32 - (maxItemsPerRow - 1) * GUTTER_SIZE) /
      maxItemsPerRow;
    if (isDesktop) {
      return 0.57 * coulumnWith;
    } else {
      return 0.57 * coulumnWith;
    }
  }, [renderWidthGrid, isDesktop, maxItemsPerRow]);

  const itemKey = useCallback(
    ({ columnIndex, data, rowIndex }: any) => {
      const index = rowIndex * maxItemsPerRow + columnIndex;
      const item = data[index];

      if (!item) return columnIndex + '-' + rowIndex;
      return `${item.name}-${columnIndex}`;
    },
    [maxItemsPerRow],
  );

  // force render to fix bug render incorrectly (supper important)
  // but maybe cause performace issue
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true,
      });
    }
  }, [isDesktop, isTablet, dimensions.width, maxItemsPerRow]);

  // update scroll state of app
  const onScroll = useCallback(
    (e: any) => {
      if (e.srcElement.scrollTop && !window.isScroll) {
        window.isScroll = true;
        dispatch(
          updateAppStateAction({
            isScrolling: true,
          }),
        );
      }
      if (!e.srcElement.scrollTop && window.isScroll) {
        window.isScroll = false;
        dispatch(
          updateAppStateAction({
            isScrolling: false,
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isLoaded) return;
    const categoryContainerElement = document.getElementById('CATEGORY');
    const scrollComponent = categoryContainerElement?.lastChild?.lastChild;
    if (scrollComponent) {
      scrollComponent.addEventListener('scroll', onScroll);
    }

    return () => {
      window.isScroll = false;
      dispatch(
        updateAppStateAction({
          isScrolling: false,
        }),
      );
      scrollComponent?.removeEventListener('scroll', onScroll);
    };
  }, [isMobile, isLoaded, onScroll, dispatch]);

  const style = useMemo(() => {
    // for mobile
    let padding = isScrolling ? '196px 16px 0px' : '196px 16px 0px';

    if (isDesktop) {
      padding = isScrolling ? '196px 24px 0px' : '228px 24px 0px';
    }
    if (isTablet) {
      padding = isScrolling ? '160px 24px 0px' : '196px 24px 0px';
    }

    return {
      padding,
    };
  }, [isDesktop, isTablet, isScrolling]);

  const sortOptionContainerTopPosition = useMemo(() => {
    // for mobile
    let top = 136;
    let padding = '0px 16px';

    if (isTablet) {
      padding = '0px 24px';
    }

    if (isDesktop) {
      top = isScrolling ? 136 : 168;
      padding = '0px 24px';
    }

    return {
      top,
      padding,
    };
  }, [isScrolling, isTablet, isDesktop]);

  return (
    <>
      <Grid
        container
        className={classes.sortOptionContainer}
        style={sortOptionContainerTopPosition}
      >
        <div className={classes.sortLabelContainer}>
          <Typography className={classes.sortLabel}>Sort By</Typography>
        </div>
        <div>
          <MenuComponent
            options={categoriesSortOptions}
            selectedOption={sortdOption!}
            className={classes.sortOption}
            onChange={handleChangeSortOption}
          />
        </div>
      </Grid>
      <div id="CATEGORY" style={style}>
        <InfiniteLoader
          ref={infiniteLoaderRef}
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => {
            return isMobile ? (
              <ReactWindowScroller isGrid>
                {({ ref: ref1, outerRef, style, onScroll }: any) => (
                  <VariableSizeGrid
                    itemKey={itemKey}
                    columnCount={maxItemsPerRow}
                    columnWidth={columnWidth}
                    height={window.innerHeight}
                    ref={(gridRef1) => {
                      gridRef.current = gridRef1;
                      ref1.current = gridRef1;
                      return ref(gridRef);
                    }}
                    innerElementType={innerElementType}
                    rowCount={renderRowCount}
                    onItemsRendered={newItemsRendered(onItemsRendered)}
                    rowHeight={rowHeight}
                    width={renderWidthGrid + (isDesktop ? 31 : 0)}
                    itemData={listCategories.data}
                    className={classes.container}
                    style={style}
                    outerRef={outerRef}
                    onScroll={onScroll}
                  >
                    {Cell}
                  </VariableSizeGrid>
                )}
              </ReactWindowScroller>
            ) : (
              <div
                style={{
                  paddingBottom: 'unset',
                  width: renderWidthGrid + (isDesktop ? 31 : 0),
                }}
              >
                <VariableSizeGrid
                  itemKey={itemKey}
                  columnCount={maxItemsPerRow}
                  columnWidth={columnWidth}
                  height={renderHeightOfGrid}
                  ref={(gridRef1) => {
                    gridRef.current = gridRef1;
                    return ref(gridRef1);
                  }}
                  innerElementType={innerElementType}
                  rowCount={renderRowCount}
                  onItemsRendered={newItemsRendered(onItemsRendered)}
                  rowHeight={rowHeight}
                  width={renderWidthGrid + (isDesktop ? 31 : 0)}
                  itemData={listCategories.data}
                  className={classes.container}
                >
                  {Cell}
                </VariableSizeGrid>
              </div>
            );
          }}
        </InfiniteLoader>
      </div>
    </>
  );
};

export default Categories;
