import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AboutContent from './AboutContent';
import { contents } from './AboutContent/content';
import AddIcon from 'icons/AddIcon';
import useTitle from 'hooks/useTitle';

const About = () => {
  const classes = useStyles();
  const history = useHistory();

  const onredirectHome = () => {
    history.push('/');
  };

  const onredirectAbout = () => {
    history.push('/about');
  };

  const directAddNFTHandle = () => {
    history.push('/add-nft');
  };

  useTitle('About | Mintedgem');

  return (
    <Box className={classes.main}>
      <div className={classes.crumpet}>
        <span onClick={onredirectHome}>Home · </span>
        <span onClick={onredirectAbout} style={{ fontWeight: 700 }}>
          About
        </span>
      </div>
      <Typography className={classes.title}>About</Typography>

      <div className={classes.contentWrap}>
        {contents.map((content) => (
          <AboutContent {...content} key={content.tit} />
        ))}
      </div>

      <div className={classes.btnWrap}>
        <Button className={classes.homeBtn} onClick={onredirectHome}>
          Home
        </Button>
        <Button
          className={classes.addNftBtn}
          startIcon={<AddIcon color="#FFFFFF" />}
          onClick={directAddNFTHandle}
        >
          Add NFT
        </Button>
      </div>
    </Box>
  );
};

export default About;

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    padding: '200px 293px 153px 293px',
    [theme.breakpoints.down('md')]: {
      padding: '136px 24px 140px 24px',
    },
  },
  crumpet: {
    color: '#6F6BC5',
    position: 'relative',
    cursor: 'pointer',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
      left: -190,
      bottom: 20,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  title: {
    fontSize: 42,
    fontWeight: 700,
    lineHeight: '58.8px',
    marginBottom: 40,
  },
  contentWrap: {
    '& div:last-child': {
      marginBottom: 0,
    },
  },
  btnWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '48px',
    marginTop: 80,
  },
  homeBtn: {
    cursor: 'pointer',
    border: 'unset',
    background: 'unset',
    color: '#6F6BC5',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Poppins, sans-serif',
  },
  addNftBtn: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 158,
    height: 60,
    background: '#6F6BC5',
    border: 'unset',
    gap: 12,
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
  },
}));
