import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  container: {},
  title: {
    fontWeight: 500,
    fontSize: 28,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  content: {
    whiteSpace: 'pre-line',
    fontWeight: 300,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));
