export enum LoginActionTypeEnum {
  LOGOUT = 'login/logout',
}

export enum AppReducerEnum {
  UPDATE_APP_REDUCER = 'UPDATE_APP_REDUCER',
}

export enum AsyncTaskTypeEnum {
  ASYNC_TASK_START = 'ASYNC_TASK_START',
  ASYNC_TASK_STOP = 'ASYNC_TASK_STOP',
  ASYNC_TASK_RESET = 'ASYNC_TASK_RESET',
}

export enum AuthorsActionTypeEnum {
  READ_LIST_AUTHORS_REQUEST = ' READ_LIST_AUTHORS_REQUEST',
}

export enum FilterActionTypeEnum {
  CHANGE_FILTER = 'CHANGE_FILTER',
  RESET_FILTER = 'RESET_FILTER',
}

export enum CollectionActionTypeEnum {
  GET_COLLECTIONS = 'GET_COLLECTIONS',
  RESET_COLLECTIONS = 'RESET_COLLECTIONS',
}

export enum NFTsActionTypeEnum {
  GET_NFTS = 'GET_NFTS',
  GET_NFT_PREVIEW = 'GET_NFT_PREVIEW',
  GET_NFT_DETAIL = 'GET_NFT_DETAIL',
  GET_MY_NFTS = 'GET_MY_NFTS',
  DELETE_MY_NFTS = 'DELETE_MY_NFTS',
  DELETE_BY_ADMIN = 'DELETE_BY_ADMIN',
  CREATE_NEW_NFT = 'CREATE_NEW_NFT',
  GET_NFTS_BEFORE = 'GET_NFTS_BEFORE',
  GET_NFTS_TABLET_AT_FIRST = 'GET_NFTS_TABLET_AT_FIRST',
}

export enum DialogActionTypeEnum {
  CHANGE_OPEN_STATE = 'CHANGE_OPEN_STATE',
}

export enum UserActionTypeEnum {
  LOGIN = 'LOGIN',
  GET_USER = 'GET_USER',
  LOGOUT = 'LOGOUT',
  UPDATE_USER = 'UPDATE_USER',
}

export enum AdminActionTypeEnum {
  LOGIN = 'LOGIN_ADMIN',
}

export enum SummarizeActionTypeEnum {
  GET_SUMMARIZE = 'GET_SUMMARIZE',
}

export enum BlockActionTypeEnum {
  GET_ALL_BLOCK = 'GET_ALL_BLOCK',
  GET_BLOCKS_BY_TIME = 'GET_BLOCKS_BY_TIME',
}

export enum BlockCategoriesActionTypeEnum {
  GET_CATEGORIES_BLOCK = 'GET_CATEGORIES_BLOCK',
  RESET_BLOCK = 'RESET_BLOCK',
  SEARCH_DATA_CATEGORIES_BLOCK = 'SEARCH_DATA_CATEGORIES_BLOCK',
}

export enum NotificationActionTypeEnum {
  GET_MY_NOTIFICATIONS = 'GET_MY_NOTIFICATIONS',
  MARK_AS_SEEN = 'MARK_AS_SEEN',
  UPDATE_TOTAL_NOT_SEEN = 'UPDATE_TOTAL_NOT_SEEN',
}

export enum AddNftActionTypeEnum {
  GET_ESTIMATE = 'ESTIMATE',
  GET_ESTIMATE_SUCCESS = 'GET_ESTIMATE_SUCCESS',
  GET_ESTIMATE_FAIL = 'GET_ESTIMATE_FAIL',
  RESET_ESTIMATE = 'RESET_ESTIMATE',
  GET_PRICE_NFT_SUCCESS = 'GET_PRICE_NFT_SUCCESS',
  SET_DEFAULT_POSTION = 'SET_DEFAULT_POSTION',
  ADD_CATEGORIES = 'ADD_CATEGORIES',
  DELETE_CATEGORY = 'DELETE_CATEGORY_IN_ESTIMATE_NFT',
}

export enum TransactionActionEnum {
  GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS',
  GET_TRANSACTION_VOLUME = 'GET_TRANSACTION_VOLUME',
  DELETE_TRANSACTION = 'DELETE_TRANSACTION',
}
export enum CategoriesActionEnum {
  GET_FILTER_CATEGORIES = 'GET_FILTER_CATEGORIES',
  GET_CATEGORIES_BLOCK = 'GET_CATEGORIES_BLOCK',
  RESET_FILTER_CATEGORIES = 'RESET_FILTER_CATEGORIES',
}

export enum WalletBlockerActionEnum {
  GET_ALL_WALLER_BLOCKERS = 'GET_ALL_WALLER_BLOCKERS',
  BLOCK_USER = 'BLOCK_USER',
  UNBLOCK_USER = 'UNBLOCK_UNSER',
}

export enum AppStateEnum {
  UPDATE_APP_STATE = 'UPDATE_APP_STATE',
}

export enum EditNFTActionTypeEnum {
  GET_NFT_DETAIL_SUCCESS = 'GET_NFT_DETAIL_SUCCESS',
  GET_CURRENT_ESTIMATE_INFO_SUCCESS = 'GET_CURRENT_ESTIMATE_SUCCESS',
  GET_PRICE_NFT_FOR_EDIT_SUCCESS = 'GET_PRICE_NFT_FOR_EDIT_SUCCESS',
  GET_ESTIMATE_FOR_EDIT = 'GET_ESTIMATE_FOR_EDIT',
  GET_ESTIMATE_FOR_EDIT_SUCCESS = 'GET_ESTIMATE_FOR_EDIT_SUCCESS',
  GET_ESTIMATE_FOR_EDIT_FAIL = 'GET_ESTIMATE_FOR_EDIT_FAIL',
  RESET_ESTIMATE_FOR_EDIT = 'RESET_ESTIMATE_FOR_EDIT',
  DELETE_CATEGORY = 'DELETE_CATEGORY_IN_EDIT_NFT',
}

export enum SystemMessageActionTypeEnum {
  UPDATE_SYSTEM_MESSAGE = 'UPDATE_SYSTEM_MESSAGE',
}

export enum ContactActionTypeEnum {
  SEND_EMAIL = 'SEND_EMAIL',
}

export enum RecentSalesActionEnum {
  GET_RECENT_SALES = 'GET_RECENT_SALES',
}

export enum CategoriesActionTypeEnum {
  SEARCH_CATEOGRIES_COLLECTIONS = 'SEARCH_CATEOGRIES_COLLECTIONS',
  RESET_SEARCH = 'RESET_SEARCH',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_TABLE_CATEGORIES = 'GET_TABLE_CATEGORIES',
  GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES',
  GET_SEARCH_CATEGORIES = 'GET_SEARCH_CATEGORIES',
  GET_CATEGORIES_START = 'GET_CATEGORIES_START',
}

export enum ListCategoriesActionTypeEnum {
  GET_LIST_CATEGORIES = 'GET_LIST_CATEGORIES',
}
