/* eslint-disable */
import React from 'react';
export interface IQuestionContent {
  id: number;
  question: string;
  answer: string;
  img?: string;
}

export const questionContent: IQuestionContent[] = [
  {
    id: 0,
    question: 'How are the 1001 squares divided?',
    answer:
      'Each gallery is divided into 12 blocks containing the following number of squares: 1, 9, 9, 36, 64, 81, 100, 100, 100, 100, 400, 1 = 1001\n\nItems after 1001 cannot be filtered.',
  },
  {
    id: 29,
    question: 'How do I add my NFT to a gallery?',
    answer:
      'Click +ADD NFT and paste your NFT link. Our system reads your NFT’s details and suggests all possible galleries to which your NFT can be added. Follow the remaining steps to post on all suggested galleries with no extra cost.\n\nIf you encounter any issues adding an NFT to a specific gallery, let us know asap so we can fix the problem.\n\nNote: OpenSea’s API currently only supports adding Ethereum NFT links. Similarly, Magic Eden’s API currently only supports adding Solana NFT links.',
  },
  {
    id: 1,
    question: 'How are NFTs ranked across galleries?',
    answer:
      'All NFTs are ranked by default in order of ad price per day. Ad price per day is calculated by dividing the amount paid by chosen time in days. \n\n$Amount paid ÷ Active time in days = $Ad price per day\n\nThis doesn’t mean NFTs have to be placed for an exact amount of days. Ex: A seller who chooses to pay $10 and places their NFT for only 1 hour has an ad price per day of $240.\n\n$10 ÷ (1H ÷ 24H = 0.04167 days) = $240 per day.',
  },
  {
    id: 2,
    question: 'What NFT marketplace links are supported?',
    answer:
      'Add your links from OpenSea, LooksRare and Magic Eden. We’re continuously working to support more new marketplaces each day.\n\nNote: OpenSea’s API currently only supports adding Ethereum NFT links. Similarly, Magic Eden’s API currently only supports adding Solana NFT links.',
  },
  {
    id: 3,
    question: 'Can I add my NFT for free?',
    answer:
      'Yes! You still need to pay a small transaction fee to confirm the transaction on the blockchain and therefore still need to have some crypto in your wallet. We recommend FTM, AVAX, or MATIC as they have a near 0 transaction fee, but you can also use ETH or BNB Smart Chain.',
  },
  {
    id: 4,
    question: 'Is there a commission on my NFT’s sale?',
    answer:
      'No. Never. Ever. Ever. There are no commissions or hidden fees at time of sale or any other time.',
  },
  {
    id: 5,
    question: 'Why does my NFT’s position keep changing?',
    answer:
      'Items rank across galleries in order of ad price per day, so if a new NFT gets added, all NFTs with a lower ad price per day are bumped one position down; But anytime an active NFT’s remaining time has ended, all NFTs with a lower ad price per day are bumped one position up.\n\nYou can track and edit your item’s current position at any time in your profile.',
  },
  {
    id: 6,
    question: 'What happens to my NFT if it gets pushed below 1001?',
    answer:
      'While your item is below 1001 it is not filterable, but can still be found as normal in the DeadZone: the block containing all active items after 1001.\n\nYou can edit and track your item’s current position at any time in your profile.',
  },
  {
    id: 7,
    question: 'Can I edit my NFT’s position after posting?',
    answer:
      'Yes. On your profile, your item has an Edit button which allows you to change your position, time, and value settings. You still have to pay a transaction fee to confirm your changes on the blockchain.',
    // img: '/images/faq/faq_edit.png',
  },
  {
    id: 8,
    question: 'Can I change the price of my NFT?',
    answer:
      'You can change the price, listing type, or cancel the listing in its original marketplace at any time. Currently, we do not support item updates so your item will showcase the same price and details of when it was added. ',
  },
  {
    id: 9,
    question: 'Why does my NFT URL not work when I try to + ADD NFT?',
    answer:
      'If you’re correctly providing the URL of an individual item, not a collection, it’s likely that the NFT you’re trying to add is from a marketplace not yet supported by us.\n\nNote: OpenSea’s API currently only supports adding Ethereum NFT links. Similarly, Magic Eden’s API currently only supports adding Solana NFT links.',
  },

  {
    id: 10,
    question: 'What is First place, Last place, 24H volume, All time volume?',
    answer:
      'First and Last place is the price paid by a user to place their item in 1st and 1001st for a full day. 24H volume and All time volume represent the total volume paid by all users to add items in that respective amount of time.',
  },
  {
    id: 11,
    question: 'How much time should I list my NFT for?',
    answer:
      'The more time the better, but keep in mind the longer your item is placed close to the top, the more likely people are to get a chance to see it. Still, be realistic about the desirability of your NFT and price it accordingly. Remember, all NFTs are cool, but some are cooler than others.',
  },
  {
    id: 12,
    question: 'Do I need to have my NFT in the same wallet I use to pay?',
    answer:
      'No. We fetch your item’s image and listing details through the provided marketplace listing URL, so you can pay using a completely different wallet from the one holding the NFT.',
  },
  {
    id: 13,
    question: 'Is 1001 Squares of NFT safe?',
    answer: 'jsx',
  },
  {
    id: 14,
    question: 'What currency should I pay with?',
    answer: 'jsx',
  },
  {
    id: 15,
    question: 'Is the sale of my NFT guaranteed?',
    answer:
      'Unfortunately nobody can guarantee the sale of an NFT. Nevertheless, 1001 Squares of NFT is a great place to increase your item’s exposure and get more potential buyers to land on your listing page, upping the chances of a sale.',
  },
  {
    id: 16,
    question: 'What happens to my NFT when the time I specified is over?',
    answer:
      'When your item’s active time is over, it will be removed from all galleries, but will stay on your profile unlisted. You can re-add from there at any time.',
  },
  {
    id: 17,
    question: 'How do I disconnect my wallet?',
    answer:
      'To disconnect your wallet visit your profile page and click the settings icon to open up the settings panel. Next to your wallet address, you can find an exit icon. Clicking it will disconnect your wallet and log you out of your profile.',
    img: '/images/faq/faq_disconnect_wallet.png',
  },

  {
    id: 18,
    question: 'What happens if I disconnect my wallet?',
    answer:
      'Disconnecting your wallet is similar to logging out of a profile. When your wallet is disconnected you will not be able to access your profile but any active NFTs will remain active. Simply reconnect your wallet to access your profile again.',
  },
  {
    id: 19,
    question: 'Why can’t I connect my wallet?',
    answer:
      'If you’re having a problem connecting your wallet, in your wallet’s Dapp connections try disconnecting 1001squaresofnft.com and try again. If that doesn’t solve the issue and the error persists let us know.',
  },
  {
    id: 20,
    question: 'When I clicked CONFIRM PAYMENT my transaction failed. Why?',
    answer:
      'If you didn’t have a connection error, make sure you have enough funds to cover the desired payment plus the transaction fee. If the error persists be sure to contact us.',
  },
  {
    id: 21,
    question: 'How many times can I add or clone the same NFT?  ',
    answer:
      'A single wallet can add the same NFT multiple times. This helps users showcase in multiple blocks at once. To speed things up, on your profile, you can use an NFT’s Clone button to create an inactive version of the NFT. Click on the recently created clone’s Add button and follow the normal steps to add it to the 1001 Squares of NFT.',
  },
  {
    id: 22,
    question: 'What happens if I delete my NFT?',
    answer:
      'After pressing Delete on an active NFT, you will be shown a warning popup asking you if you’re sure you want to delete. Deleting an active NFT with time remaining will remove it from all galleries, and any unused value and time from the NFT will be lost. This action cannot be undone.',
  },
  {
    id: 23,
    question: 'What are the supported file types for NFTs?',
    answer: 'GIF, PNG, JPEG, JPG, WebM, WebP, MP4, SVG',
  },
  {
    id: 24,
    question: 'How are items with the same ad price per day ranked?',
    answer:
      'Since ad price per day is paid in cryptocurrency, which changes constantly in relation to the price of USD, it is uncommon for items to have the exact same final square price.\n\nEx: There are two items positioned on the front page appearing to have the same square price per day of $0.44, but one is positioned before the other. However, when examining a more precise value from the crypto to dollar conversion of each transaction, you’d find the items’ true square price would be $0.4421 and $0.4474. In these cases, the additional decimals decide the ranking, and in the case two values are absolutely identical, items are ranked in order of arrival.',
  },
  {
    id: 25,
    question: 'On the 12 blocks, why is square 1001 the same size as square 1?',
    answer:
      'We made squares 1 and 1001 the same size so that any NFT can achieve their moment of spotlight. However, square 1001 is by far the most difficult square to hold. If any new NFT is added from positions 1-1001, the NFT in position 1001 is pushed down to 1002 (the first position of the DeadZone) and if the time ends for any NFT in positions 1-1001, the NFT in position 1001 moves up to 1000 (the last position of block 12 - the largest block).',
    // img: '/images/faq/faq_1001_no_dz.png',
  },
  {
    id: 26,
    question: 'What is the DeadZone?',
    answer:
      'The DeadZone holds all the NFTs after 1001. Items in the DeadZone cannot be filtered and typically get significantly less exposure, but besides that work exactly the same. As new items get added to the front page and old ones leave, items in the DeadZone can be further pushed down, or bumped back up above 1001. You can edit your NFT’s position in your profile at any time.',
    // img: '/images/faq/faq_dz.png',
  },
  {
    id: 27,
    question: 'Can I add my NFT to the DeadZone?',
    answer: 'Yes.',
  },
  {
    id: 28,
    question: 'How many items fit in the DeadZone?',
    answer: '∞',
  },
];
