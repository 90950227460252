import React, { useMemo, useState, useCallback } from 'react';
import { Button, Grid, Tooltip } from '@material-ui/core';
import { useStyles } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import { ReactComponent as EthIcon } from 'assets/eth-icon.svg';
import { ReactComponent as EthIconWhite } from 'assets/icon-white.svg';
import IconNext from 'assets/next-icon.svg';
import IconPrev from 'assets/prev-icon.svg';
import ImgArrowSeeAll from 'assets/arrow-forward.svg';
import clsx from 'clsx';
import { DataCategories } from 'types/categories';
import IconItem from 'assets/items-icon.png';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetBlockCategoriesAction } from 'store/actions/blockCategoriesAction';
import { updateFilterAction } from 'store/actions/filterActions';

export interface ScrollCategoriesProps {
  categories: DataCategories[];
}

export default function ScrollCategories(props: ScrollCategoriesProps) {
  const { categories } = props;
  const classes = useStyles();
  const [swiperIns, setSwiperInstance] = useState<any>();
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickCategory = useCallback(
    (category: DataCategories) => {
      dispatch(
        updateFilterAction({
          categories: [category.name],
          blockNumber: undefined,
          blockCategory: undefined,
          collectionIds: [],
        }),
      );
      dispatch(resetBlockCategoriesAction());
      history.push({
        pathname: `/${category.name.toLowerCase()}`,
        state: { isNftFilter: true },
      });
    },
    [history, dispatch],
  );
  const handleNegativeCategory = () => {
    history.push('/categories');
  };

  const renderItemNFT = useMemo(() => {
    return categories.map((item) => {
      const itemPrice =
        item.allTimeVolume.value < 0.0005
          ? 0
          : item.allTimeVolume.value.toFixed(4);
      return (
        <SwiperSlide key={item.name} onClick={() => onClickCategory(item)}>
          <Grid
            lg={12}
            className={classes.wrapItemScroll}
            style={{
              backgroundImage: `url(${item.imgUrl})`,
            }}
          >
            <Grid
              lg={12}
              className={clsx(classes.titleItemNFT, {
                [classes.titleItemNFTBlack]: item.mode === 'light',
              })}
            >
              /{item.name}
            </Grid>
            <Grid
              lg={12}
              className={clsx(classes.desNFT, {
                [classes.desNFTBlack]: item.mode === 'light',
              })}
            >
              {item?.description}
            </Grid>
            <Grid container className={classes.wrapInfor}>
              <Grid
                container
                className={clsx(classes.wrapPrice, {
                  [classes.wrapPriceBlack]: item.mode === 'light',
                })}
              >
                <Grid>
                  <img src={IconItem} alt="" className={classes.iconItem} />
                </Grid>
                <Grid
                  className={clsx(classes.totalItem, {
                    [classes.totalItemBlack]: item.mode === 'light',
                  })}
                >
                  {item?.totalItem.value}
                </Grid>
                <Grid
                  className={clsx(classes.titlePrice, {
                    [classes.titlePriceBlack]: item.mode === 'light',
                  })}
                >
                  {item?.totalItem?.value > 1 ? 'Items' : 'Item'}
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={clsx(classes.wrapPrice, {
                  [classes.wrapPriceBlack]: item.mode === 'light',
                })}
              >
                {item.mode === 'light' ? (
                  <EthIconWhite width={12.5} height={12.5} />
                ) : (
                  <EthIcon width={12.5} height={12.5} />
                )}
                <Tooltip title={item.allTimeVolume.value}>
                  <Grid
                    className={clsx(classes.priceItem, {
                      [classes.priceItemBlack]: item.mode === 'light',
                    })}
                  >
                    {itemPrice}
                  </Grid>
                </Tooltip>
                <Grid
                  className={clsx(classes.titlePrice, {
                    [classes.titlePriceBlack]: item.mode === 'light',
                  })}
                >
                  All time volume
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SwiperSlide>
      );
    });
  }, [classes, categories, onClickCategory]);

  return (
    <Grid className={classes.main}>
      <Grid className={classes.wrapButton}>
        <Button
          variant="text"
          onClick={handleNegativeCategory}
          disableTouchRipple
          className={classes.linkSeeAll}
        >
          see all categories
          <img className={classes.imgArrowAll} src={ImgArrowSeeAll} alt="" />
        </Button>
      </Grid>
      <Grid>
        <Swiper
          id={'always-be-swipin'}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          centeredSlides={false}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          watchSlidesProgress={true}
          className="mySwiper"
          breakpoints={{
            1440: {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesPerGroup: 3,
            },
            1920: {
              slidesPerView: 3,
              spaceBetween: 50,
              slidesPerGroup: 3,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 10,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
              slidesPerGroup: 2,
            },
          }}
        >
          {renderItemNFT}
        </Swiper>
      </Grid>
      <Grid className={classes.wrapButtonScroll} container>
        <div className={classes.iconPrev} onClick={() => swiperIns.slidePrev()}>
          <img src={IconPrev} alt="" />
        </div>
        <div className={classes.iconPrev} onClick={() => swiperIns.slideNext()}>
          <img src={IconNext} alt="" />
        </div>
      </Grid>
    </Grid>
  );
}
